import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import NavSpacer from './components/NavSpacer';
import NavOverlay from './components/NavOverlay';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavExternal from './components/NavExternal';
import NavSingleItemWithCount from './components/NavSingleItemWithCount';
import NavSingleItemWithHighlight from './components/NavSingleItemWithHighlight';
import NavSingleItemWithPendingSetup from './components/NavSingleItemWithPendingSetup';
import NavSingleItemWithPendingVoucher from './components/NavSingleItemWithPendingVoucher';

import ToggleSidebarButton from './components/ToggleSidebarButton';

import NavDropdownItem from './components/NavDropdownItem';
import PageAlertContext from '../PageAlert/PageAlertContext';

import { Button } from 'reactstrap';

export default class SidebarNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  navItems = (items, type) => {
    const {
      perksDisabled,
      perkTitle,
      company,
      complianceData,
      stripeChargesEnabled,
      stripePayoutsEnabled,
      stripeDataFetched,
      shopifyApiKey,
      woocommerceApiKey,
      activeAPI,
      vouchers,
      linkedCompaniesAPIs,
    } = this.props;

    if (type === 'client') {
      const { companyName, companyType, companyNumber, website, primaryVoucherImage, logo, category1, description } = company;
      const itemsClone = [...items];
      const found = itemsClone.findIndex((element) => element.url === '/perks');

      if (found && found !== -1) {
        if (perksDisabled) {
          itemsClone.splice(found, 1);
        } else {
          itemsClone[found].name = perkTitle;
        }
      }

      const vouchersLength = vouchers ? Object.keys(vouchers).length : 0;

      let basicInformationSetup = false, apiSetup = false, paymentsSetup = false;

      console.log('companycompany linkedCompaniesAPIs', linkedCompaniesAPIs);

      if (company.linkedCompaniesAccount) {
        if (linkedCompaniesAPIs && Object.keys(linkedCompaniesAPIs).length) {
          apiSetup = true;
        }
      } else {
        if (activeAPI) {
          if (activeAPI === 'woocommerce') {
            if (woocommerceApiKey && Object.keys(woocommerceApiKey).length) {
              apiSetup = true;
            }
          } else if (activeAPI === 'shopify') {
            if (shopifyApiKey && Object.keys(shopifyApiKey).length) {
              apiSetup = true;
            }
          } else if (activeAPI === 'manual') {
            apiSetup = true;
          }
        } else if (shopifyApiKey && Object.keys(shopifyApiKey).length) {
          apiSetup = true;
        }
      }

      let complianceDataQ1 = false, complianceDataQ2 = false, complianceDataQ3 = false, complianceDataQ4 = false, complianceDataQ5 = false;

      if (complianceData) {
        if (complianceData.question1 || complianceData.question1 === false) complianceDataQ1 = true;
        if (complianceData.question2 || complianceData.question2 === false) complianceDataQ2 = true;
        if (complianceData.question3 || complianceData.question3 === false) complianceDataQ3 = true;
        if (complianceData.question4 || complianceData.question4 === false) complianceDataQ4 = true;
        if (complianceData.question5 || complianceData.question5 === false) complianceDataQ5 = true;
      }

      if (company && Object.keys(company) && Object.keys(company).length && company.dateCreated && company.owner) {
        if ((companyName && (companyType && (companyNumber || companyType === 'individual')) && website && primaryVoucherImage && logo && category1 && description
          && complianceDataQ1 && complianceDataQ2 && complianceDataQ3 && complianceDataQ4 && complianceDataQ5)) {
          basicInformationSetup = true;
        }

        if ((stripeChargesEnabled && stripePayoutsEnabled && stripeDataFetched) || vouchersLength) {
          paymentsSetup = true;
        }

        const basicInformation = itemsClone.findIndex((element) => element.url === '/basic-information');
        const api = itemsClone.findIndex((element) => element.url === '/api');
        const payments = itemsClone.findIndex((element) => element.url === '/payments');

        if (basicInformation && basicInformation !== -1) {
          itemsClone[basicInformation].setupDone = basicInformationSetup;
          itemsClone[basicInformation].setupId = 'basicInformation';
        }

        if (api && api !== -1) {
          itemsClone[api].setupDone = apiSetup;
          itemsClone[api].setupId = 'api';
        }

        if (payments && payments !== -1 && stripeDataFetched) {
          itemsClone[payments].setupDone = paymentsSetup;
          itemsClone[payments].setupId = 'payments';
        }
      }

      let setupCompleteFully = false;
      if (basicInformationSetup && apiSetup && paymentsSetup) {
        setupCompleteFully = true;
      }

      return itemsClone.map((item, index) => this.itemTypeCompany(item, index, setupCompleteFully, vouchersLength));
    } else {
      const itemsClone = [...items];
      const found = itemsClone.findIndex((element) => element.url === '/perks');

      if (found && found !== -1) {
        if (perksDisabled) {
          itemsClone.splice(found, 1);
        } else {
          itemsClone[found].name = perkTitle;
        }
      }
      return itemsClone.map((item, index) => this.itemType(item, index));
    }
  };

  itemTypeCompany = (item, index, setupCompleteFully, vouchersLength) => {
    const { user, adminStatistics, branding, location, shadeColor, perkTitle } = this.props;

    console.log('itemTypeCompany', item, setupCompleteFully, vouchersLength);

    if (item.name && item.name === 'divider') {
      return <div style={{ height: 0, width: '100%', borderBottom: '1px solid white' }} />;
    } else if (item.children) {
      return <NavDropdownItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} adminStatistics={adminStatistics} user={user} key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} />;
    } else if (item.name && item.name === 'Setup Help') {
      return (<NavSingleItemWithHighlight rcHighlight={user.rcHighlight} isActive={user && user.company && !user.rcHighlight} location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} />)
    } else if (item.name && item.name === perkTitle) {
      return (<NavSingleItemWithHighlight rcHighlight={user.perksHighlight} isCompanyPerksTab={user && user.company} isActive={user && user.company && !user.perksHighlight} location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} />)
    } else if (item.setupDone === false && item.setupId) {
      return (<NavSingleItemWithPendingSetup setupDone={item.setupDone} isActive={user && user.company && !item.setupDone} location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} />)
    } else if (setupCompleteFully && !vouchersLength && item.url && item.url === "/new-voucher") {
      return (<NavSingleItemWithPendingVoucher isActive={true} location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} />)
    } else if (!setupCompleteFully && !vouchersLength && item.url && item.url === "/new-voucher") {
      return <NavSingleItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} />;
    } else if (item.divider) {
      return <NavDivider key={index} />;
    } else {
      return <NavSingleItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} />;
    }
  };

  itemType = (item, index) => {
    const { user, adminStatistics, branding, location, shadeColor, perkTitle } = this.props;

  //  console.log('user.perksHighlight', user.perksHighlight, user);

    if (item.name && item.name === 'divider') {
      return <div style={{ height: 0, width: '100%', borderBottom: '1px solid white' }} />;
    } else if (item.children) {
      return <NavDropdownItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} adminStatistics={adminStatistics} user={user} key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} />;
    } else if (item.name && item.name === 'Setup Help') {
      return (<NavSingleItemWithHighlight rcHighlight={user.rcHighlight} isActive={user && user.company && !user.rcHighlight} location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} />)
    } else if (item.name && item.name === perkTitle) {
      return (<NavSingleItemWithHighlight rcHighlight={user.perksHighlight} isCompanyPerksTab={user && user.company} isActive={user && user.company && !user.perksHighlight} location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} />)
    } else if (adminStatistics && adminStatistics.newCompaniesCount && adminStatistics.newCompaniesCount.count && adminStatistics.newCompaniesCount.count > 0 && item.name && item.name === 'Companies') {
      return (<NavSingleItemWithCount location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} count={adminStatistics.newCompaniesCount.count} />)
    } else if (adminStatistics && adminStatistics.pendingVouchersCount && adminStatistics.pendingVouchersCount.count && adminStatistics.pendingVouchersCount.count > 0 && item.name && item.name === 'Campaigns') {
      return (<NavSingleItemWithCount location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} count={adminStatistics.pendingVouchersCount.count} />)
    } else if (item.divider) {
      return <NavDivider key={index} />;
    } else {
      return <NavSingleItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} />;
    }
  };

  navBrand = () => {
    const { branding, logo, logoText, toggleSidebar, isSidebarCollapsed, isMobile } = this.props;

    return (
      <div className="site-logo-bar">
        {(isSidebarCollapsed && isMobile) ? (
          <>
            <NavLink to="/" className="navbar-brand">
              {logo && <img src={logo} alt="" />}
              {logoText && <span style={{ color: branding.sidebarTextColor }} className="logo-text">{logoText}</span>}
            </NavLink>
            <ToggleSidebarButton
              toggleSidebar={() => {}}
              isSidebarCollapsed={!isSidebarCollapsed}
              color="primary"
            />
          </>
        ) : (
          <>
            {!isSidebarCollapsed ? (
              <>
                <NavLink to="/" className="navbar-brand">
                  {logo && <img src={logo} alt="" />}
                  {logoText && <span style={{ color: branding.sidebarTextColor }} className="logo-text">{logoText}</span>}
                </NavLink>
                <ToggleSidebarButton
                  toggleSidebar={toggleSidebar}
                  isSidebarCollapsed={isSidebarCollapsed}
                  color="primary"
                />
              </>
            ) : (
              <div className="navbar-brand active">
                {logo && <img src={logo} alt="" />}
                {logoText && <span style={{ color: branding.sidebarTextColor }} className="logo-text">{logoText}</span>}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  navContact = () => {
  //  const { logout, branding } = this.props;
    const { branding, location, shadeColor } = this.props;

    return <NavSingleItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding}
    item={{
      name: 'Contact',
      url: '/contact',
    }}
    key={99}
    />;
  };

  navLogout = () => {
    const { logout, branding } = this.props;

    return (
      <div className="nav-item">
        <Button color="link" className="logout-button" style={{ color: branding ? branding.sidebarTextColor : '#FFF' }} onClick={logout}>
          Logout
        </Button>
      </div>
    );
  };

  navFAQ = () => {
    const { logout, branding, faqLinks, faqLinksArray, shadeColor } = this.props;

    console.log('faqLinksArray', faqLinksArray);

    /*
    <div className="nav-item">
      <a color="link" className="logout-button" style={{ color: branding ? branding.sidebarTextColor : '#FFF' }} href={faqLinks[key].value} target="_blank" rel="noopener noreferrer">
        {faqLinks[key].name}
      </a>
    </div> */

    return (
      <>
        <div className="nav-item">
          <a className="logout-button" style={{ color: branding ? branding.sidebarTextColor : '#FFF' }}>
            FAQs
          </a>
        </div>
        {faqLinksArray ? faqLinksArray.map((link) => (
          <NavExternal link={link.value} name={link.name} branding={branding} shadeColor={shadeColor} indent />
        )):(null)}
        {/*<div className="nav-item">
          <a color="link" className="logout-button" style={{ color: branding ? branding.sidebarTextColor : '#FFF' }} href="https://steppr.freshdesk.com/support/solutions/101000186531" target="_blank" rel="noopener noreferrer">
            General FAQs
          </a>
        </div>
        <div className="nav-item">
          <a color="link" className="logout-button" style={{ color: branding ? branding.sidebarTextColor : '#FFF' }} href="https://steppr-partner.freshdesk.com/support/home" target="_blank" rel="noopener noreferrer">
            Account FAQs
          </a>
        </div> */}
      </>
    );
  };

  navFAQ2 = () => {
    const { logout, branding, faqLinks, shadeColor } = this.props;

    console.log('faqLinks', faqLinks);

    return (
      <>
        <div className="nav-item">
          <a className="logout-button" style={{ color: branding ? branding.sidebarTextColor : '#FFF' }}>
            FAQs
          </a>
        </div>
        {faqLinks ? Object.keys(faqLinks).map((key) => (
          <NavExternal link={faqLinks[key].value} name={faqLinks[key].name} branding={branding} shadeColor={shadeColor} indent />
        )) : (null)}
      </>
    );
  };

  navUsers = () => {
    const { branding, location, shadeColor } = this.props;

    let isActive = false;
    if (location && location.pathname && location.pathname === '/users') {
      isActive = true;
    }

    let bgColor = '#0b1628';

    if (branding.sidebarPrimaryColor) {
      bgColor = branding.sidebarPrimaryColor;
    }

    const item = { name: "Users", url: "/users" }


  //  console.log('NavSingleItem test navUsers', item);

    return (<NavSingleItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} />);

    /* return (
      <div className="nav-item">
        <NavLink
          to='/users'
          style={{
            color: branding ? branding.sidebarTextColor : '#FFF',
            background: (isActive) ? shadeColor(bgColor, 20) : bgColor,
          }}
          activeClassName="active"
        >
          <span className="nav-item-label">Users</span>
        </NavLink>
      </div>
    ); */
  };

  navUserData = () => {
    const { user, branding, isLoggedIn, company, roles } = this.props;

    return (
      <div className="nav-item">
        {(isLoggedIn && user && user.uid) ? (
          <>
            <div style={{ height: 0, width: '100%', borderBottom: '1px solid white' }} />
            <div className="userDropdown-wrapper" style={{ paddingTop: 10, paddingBottom: 20, maxWidth: 'calc(100% - 25px)' }}>

              <h4
                className="userDropdown-company"
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  paddingLeft: 10,
                  paddingBottom: 4,
                  color: branding ? branding.sidebarTextColor : '#FFF', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
                }}
              >
                {(company && company.name) ? (company.name) : (user.companyName)}
              </h4>
              <h4 className="userDropdown-name" style={{ fontWeight: 'bold', fontSize: 16, paddingLeft: 10, paddingBottom: 12, color: branding ? branding.sidebarTextColor : '#FFF' }}>
                <Link to="/basic-information?tab=1" style={{ fontWeight: 'bold', fontSize: 16, color: branding ? branding.sidebarTextColor : '#FFF', textDecoration: 'none' }}>
                  {user.displayName}
                </Link>
              </h4>
              <h2 className="userDropdown-role" style={{ fontSize: 12, textAlign: 'left', paddingLeft: 30, color: branding ? branding.sidebarTextColor : '#FFF' }}>
                {(user.role === 'admin') ? 'Admin' : ((user.role === 'teamOwner') ? user.roleInCompany : (roles[user.role] ? roles[user.role].name : null))}
              </h2>
            </div>
            <div style={{ height: 0, width: '100%', borderBottom: '1px solid white' }} />
          </>
        ):(
          null
        )}
      </div>
    );
  };

  toggleSidebarOnClick = (e) => {
    const { toggleSidebar, isSidebarCollapsed } = this.props;

    if (isSidebarCollapsed) {
      e.preventDefault();
      console.log('call toggleSideCollapse');
      toggleSidebar();
    }
  }

  clickOverlay = () => {
    const { toggleSidebar, isSidebarCollapsed } = this.props;

    console.log('call toggleSideCollapse');

    if (isSidebarCollapsed) {      
      toggleSidebar();
    }
  }

  render() {
    const { user, isLoggedIn, branding, perksDisabled, isSidebarCollapsed, isMobile } = this.props;

  /*  const NavBrand = ({ logo, logoText }) => {
      return (
        <div className="site-logo-bar">
          <NavLink to="/" className="navbar-brand">
            {logo && <img src={logo} alt="" />}
            {logoText && <span style={{ color: branding.sidebarTextColor }} className="logo-text">{logoText}</span>}
          </NavLink>
        </div>
      );
    }; */

    console.log('isSidebarCollapsed', isSidebarCollapsed, isMobile);

    return (
      <>
      <PageAlertContext.Consumer>
        {consumer => {
          const hasPageAlertClass = consumer.alert ? 'has-alert' : '';
          return (
            <div>
              <div
                onClick={(e) => { this.toggleSidebarOnClick(e) }}
                style={{ overflow: 'hidden', backgroundColor: branding.sidebarPrimaryColor ? branding.sidebarPrimaryColor : '#162c50' }}
                className={`app-sidebar ${hasPageAlertClass}`}
              >
              {/*  <NavBrand logo={this.props.logo} logoText={this.props.logoText} />  */}
                {this.navBrand()}
                {(!isSidebarCollapsed || isMobile) ? (
                  <nav>
                    <ul id="main-menu">
                      {(isLoggedIn && user) ? (
                        <>
                          {this.navUserData()}
                        </>
                      ) : (null)}
                      {(isLoggedIn && user) ? (
                        <>
                          {user.role && user.roleClass === 'admin' ? (
                            <>
                              {this.navItems(this.props.nav.admin, "admin")}
                            </>
                          ) : (
                            this.navItems(this.props.nav.client, "client")
                          )}

                        </>
                      ) : (
                        this.navItems(this.props.nav.signedOut, "signedOut")
                      )}
                      {(isLoggedIn && user && user.role && user.roleClass !== 'admin') ? (
                        <>
                          {this.navFAQ()}
                        </>
                      ) : (null)}
                      <NavSpacer />
                      {(isLoggedIn && user && user.role && user.roleClass !== 'admin') ? (
                        <>
                          {this.navUsers()}
                        </>
                      ) : (null)}
                      {(isLoggedIn && user) ? (
                        <>
                          {this.navLogout()}
                        </>
                      ) : (null)}
                    </ul>
                  </nav>
                  ):(null)}
              </div>
              {(isSidebarCollapsed || (isMobile && isSidebarCollapsed)) && <NavOverlay onClick={this.clickOverlay} />}
            </div>
          );
        }}
      </PageAlertContext.Consumer>
      </>
    );
  }
}
