import { firestore, functions, firebase } from '../../lib/firebase';
import {
  GET_EMAIL_SETTINGS,
  REMOVE_EMAIL_SETTING,
  GET_COMPANY_NOTIFICATION,
} from '../index';

export const getAllEmailSettings = () => (dispatch) => new Promise(async (resolve, reject) => {
    console.log('action - getAllEmailSettings');

    const settingsRef = firestore.collection('emailSettings');

    const snapshot = await settingsRef.get();

    if (snapshot.docs) {
      const emailSettings = {};

      snapshot.docs.map(doc => {
        emailSettings[doc.id] = doc.data();
      })

      dispatch({
          type: GET_EMAIL_SETTINGS,
          payload: emailSettings,
      })

      return resolve('success');
    }
});

export const addEmailSetting = (trigger, subject, content, enabled) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!trigger) return reject('noTrigger');
  if (!subject) return reject('noSubject');
  if (!content) return reject('noContent');

  const addEmailSettingFunction = functions.httpsCallable('addEmailSetting');

  addEmailSettingFunction({
    trigger,
    subject,
    content,
    enabled: enabled ? true : false,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
});

export const updateEmailSetting = (trigger, subject, content, enabled, id) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!trigger) return reject('noTrigger');
  if (!subject) return reject('noSubject');
  if (!content) return reject('noContent');

  const updateEmailSettingFunction = functions.httpsCallable('updateEmailSetting');

  updateEmailSettingFunction({
    trigger,
    subject,
    content,
    enabled: enabled ? true : false,
    id,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
});



export const removeEmailSetting = (settingId) => (dispatch) => new Promise(async (resolve, reject) => {

  if (!settingId) return reject('no setting');

  const deleteEmailSettingFunction = functions.httpsCallable('deleteEmailSetting');

  deleteEmailSettingFunction({
    settingId,
  }).then((result) => {
    dispatch({
        type: REMOVE_EMAIL_SETTING,
        key: settingId,
    })

    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
})


export const getCompanyEmailInformation = () => (dispatch) => new Promise(async (resolve, reject) => {
  const companyNotificationRef = firestore.collection('emailSettingsCompanyInformation').doc('data');

  const snapshot = await companyNotificationRef.get();

  if (snapshot.exists) {
    const data = snapshot.data();

  //  dispatch({
  //    type: GET_COMPANY_NOTIFICATION,
  //    payload: companyNotifications,
  //  })

    return resolve((data && data.companyInformation) ? data.companyInformation : '');
  } else {
    return resolve('');
  }
});

export const updateCompanyEmailInformation = (newInfo) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyNotificationRef = firestore.collection('emailSettingsCompanyInformation').doc('data');

//  const snapshot = await companyNotificationRef.get();

  await companyNotificationRef.set({ companyInformation: newInfo }, { merge: true });
});


export const getCompanyNotifications = () => (dispatch) => new Promise(async (resolve, reject) => {
  const companyNotificationRef = firestore.collection('companyNotifications');
//  const doc = await pagesRef.get();

  const snapshot = await companyNotificationRef.get();

  if (snapshot.docs) {
    const length = snapshot.docs.length;

    let companyNotifications = [];

    snapshot.docs.map((doc, index) => {
    //  companyNotifications[doc.id] = doc.data();
      companyNotifications.push({ id: doc.id, ...doc.data() })
    });

    companyNotifications.sort((a, b) => {
      if (!a.date) {
        return 1;
      }
      if (!b.date) {
        return -1;
      }
      if ( b.date.seconds < a.date.seconds ){
        return -1;
      }
      if ( b.date.seconds > a.date.seconds){
        return 1;
      }
      return 0;
    });

    return resolve(dispatch({
      type: GET_COMPANY_NOTIFICATION,
      payload: companyNotifications,
    }));
  } else {
    return resolve(dispatch({
      type: GET_COMPANY_NOTIFICATION,
      payload: {},
    }));
  }
});

export const addCompanyNotification = (data, companies, newOnlyDate) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('addCompanyNotification', { data, companies, newOnlyDate });
  if (!data) return reject('no data');

  let companiesArr = [];

  if (companies !== 'all') {
    Object.keys(companies).map(key => {
      if (companies[key] === true) {
        companiesArr.push(key);
      }
      return null;
    });
  }

  console.log('companiesArr', { companiesArr });

//  if (!companies) return reject('no companies');

  if (companies === 'all' || companiesArr.length) {
    const companyNotificationRef = firestore.collection('companyNotifications');
  //  const doc = await pagesRef.get();

    await companyNotificationRef.add({
      text: data,
      status: 'active',
      newOnlyDate: newOnlyDate ? firebase.firestore.FieldValue.serverTimestamp(newOnlyDate) : null,
      companies: companies === 'all' ? 'all' : companiesArr,
      activeCompanies: companies === 'all' ? null : companiesArr,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    }).catch(err => {
      console.log(err);
      return reject('error');
    });

    return resolve('success');
  } else {
    return reject('no companies');
  }


});


export const deleteCompanyNotification = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!id) return reject('no id');

  const companyNotificationRef = firestore.collection('companyNotifications').doc(id);

  await companyNotificationRef.update({
    status: 'deleted',
  }).catch(err => {
    console.log(err);
    return reject('error');
  });

  return resolve('success');
});
