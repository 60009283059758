import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import {
  Col, Row,
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Media, Spinner
} from 'reactstrap';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import ScrollArea from 'react-scrollbar';

import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import ReactTooltip from 'react-tooltip';

import bell from '../../../../assets/icons/alarm.svg';
import gear from '../../../../assets/icons/cog.svg';

import { storage } from '../../../../lib/firebase';

import {
  getCompanyChanges,
} from '../../../../actions/company';

import {
  sortCompanyVouchers,
  cancelCampaignRequest,
  getCompanyVouchers,
} from '../../../../actions/vouchers';

import formattedNumber from '../../../components/FormattedNumber/FormattedNumber';
import CustomSortIcons from '../../../components/CustomSortIcons/CustomSortIcons';

import CampaignDetails from './CampaignDetails';

import line from '../../../../assets/images/line.svg';
import defaultPrimary from '../../../../assets/images/defaultPrimary.png';
import defaultLogo from '../../../../assets/images/defaultLogo.png';

class VouchersList extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    vouchers: PropTypes.shape(),
    cancelRequest: PropTypes.func.isRequired,
    sortVouchers: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
  };

  static defaultProps = {
    vouchers: {},
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      previewModal: false,
      readMore: false,
      sortColumn: 'created_at',
      sortType: 'asc',
      tableHeight: 220,
    };
  }

  componentDidMount() {
    const { user } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (user.company) {
        if (user.role === 'teamOwner') {
          this.setState({ showNoPermission: false });
        } else {
          const { roles } = this.props;

          if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.manageVouchers) {
            this.setState({ showNoPermission: false });
          } else {
            this.setState({ showNoPermission: false });
          }
        }
      }
    } else {
    }
  }

  openPreview = async (e, campaignData) => {
    const { company } = this.props;
    const { availableVouchers, discount } = campaignData;

    if (company) {
      let companyPrimaryVoucherImage;
      let logoImage;

      if (company.primaryVoucherImage && company.logo) {
        const primaryVoucherImageReference = storage.ref(company.primaryVoucherImage);
        const logoReference = storage.ref(company.logo);

        await primaryVoucherImageReference.getDownloadURL().then((url) => {
          companyPrimaryVoucherImage = url;
        }).catch((err) => {
          console.log(err);
        });

        await logoReference.getDownloadURL().then((url) => {
          logoImage = url;
        }).catch((err) => {
          console.log(err);
        });
      }

      this.setState({ availableVouchers, discount, previewModal: true, primaryVoucherImage: companyPrimaryVoucherImage || null, logo: logoImage || null });
    }
  }

  closePreview = () => {
    this.setState({ previewModal: false, primaryVoucherImage: null, logo: null });
  }

  openCampaignDetails = async (e, campaignData) => {
    const { user, company, fetchCompanyChanges } = this.props;
    const { id } = campaignData;

    if (company) {
      let companyPrimaryVoucherImage;
      let logoImage;
      let voucherImages = [];

      if (user.company) {
        await fetchCompanyChanges(user.company, id);
      }

      if (company.logo) {
        const logoReference = storage.ref(company.logo);

        await logoReference.getDownloadURL().then((url) => {
          logoImage = url;
        }).catch((err) => {
          console.log(err);
        });
      }

      if (company.primaryVoucherImage) {
        const primaryVoucherImageReference = storage.ref(company.primaryVoucherImage);

        await primaryVoucherImageReference.getDownloadURL().then((url) => {
          companyPrimaryVoucherImage = url;
        }).catch((err) => {
          console.log(err);
        });
      }

      let promises = [];

      if (company.voucherImages && company.voucherImages.length) {
        company.voucherImages.map(async (image) => {
          const imageRef = storage.ref(image);

          promises.push(imageRef.getDownloadURL().then((url) => {
            voucherImages.push(url);
          }).catch((err) => {
            console.log(err);
          }));
        })
      }

      Promise.all(promises).then(() => {
        this.setState({
          campaignDetailsId: id,
          campaignDetails: {
            ...campaignData,
            company,
            voucherImages: voucherImages || [],
            primaryVoucherImage: companyPrimaryVoucherImage || null,
            logo: logoImage || null,
          }
        });
      }).catch(err => {
        console.log(err);
        this.setState({
          campaignDetailsId: id,
          campaignDetails: {
            ...campaignData,
            company,
            voucherImages: voucherImages || [],
            primaryVoucherImage: companyPrimaryVoucherImage || null,
            logo: logoImage || null,
          }
        });
      })
    }
  }

  reloadCampaignDetails = async () => {
    const { user, adminViewAsCompany, company, getVouchers, fetchCompanyChanges } = this.props;
    const { campaignDetailsId } = this.state;

    if (user.company) {
      await fetchCompanyChanges(user.company, campaignDetailsId);
      await getVouchers(user.company);
    } else if (adminViewAsCompany) {
      await fetchCompanyChanges(adminViewAsCompany, campaignDetailsId);
      await getVouchers(adminViewAsCompany);
    }

    const { vouchersArr } = this.props;

    const campaignData = vouchersArr.find(element => element.id === campaignDetailsId);

    if (company) {
      this.setState((prevState) => ({
        campaignDetails: {
          ...prevState.campaignDetails,
          ...campaignData,
        }
      }));
    }
  }

  closeCampaignDetails = () => {
    this.setState({ campaignDetailsId: null, campaignDetails: null });
  }


  toggleReadMore = () => {
    this.setState((prevState) => ({ readMore: !prevState.readMore }))
  }

  togglePreviewStep = (step) => {
    this.setState({ previewStep: step });
  }

  sort = (sortColumn, sortType) => {
    const { sortVouchers } = this.props;

    sortVouchers(sortColumn, sortType);

    this.setState({ sortType, sortColumn });
  }

  cancelCampaignRequest = (campaignId) => {
    this.setState({ buttonPressed: campaignId });

    const { cancelRequest } = this.props;

    cancelRequest(campaignId).then(() => {
      this.setState({ buttonPressed: null });
    }).catch(err => {
      console.log(err);
      this.setState({ buttonPressed: null });
    });
  }

  toggleTableSize = () => {
    const { tableHeight } = this.state;

    console.log('toggleTableSize', tableHeight);

    if (tableHeight === 220) {
      this.setState({ tableHeight: 470 });
    } else {
      this.setState({ tableHeight: 220 });
    }
  }

  copyCampaignLink = async (id) => {
    const { hostname, search, pathname } = window.location;

    let text;

    console.log('copyCampaignLink hostname', hostname);
    if (hostname.includes('app.steppr.com')) {
      text = `https://steppr.com/campaign/${id}`;
    } else {
      text = `https://steppr-client.web.app/campaign/${id}`;
    }
    try {
      await navigator.clipboard.writeText(text);
    } catch (e) {
    }
  }

  render() {
    const { company, vouchersArr, dashboardTooltips, showNoPermission, companyPaymentsTotals, companyPaymentsTotalsPerCampaign, branding } = this.props;
    const {
      previewModal,
      readMore,
      sortType,
      sortColumn,
      availableVouchers,
      discount,
      campaignDetails,
      campaignDetailsId,
      previewStep,
      buttonPressed,
      tableHeight,
    } = this.state;

    console.log('tableHeight', tableHeight);

    return (
      <div>
        <div class="table-responsive">
          {dashboardTooltips.status ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='status-tooltip'>
              <span>{dashboardTooltips.status ? dashboardTooltips.status.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.request ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='request-tooltip'>
              <span>{dashboardTooltips.request ? dashboardTooltips.request.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.campaignName ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='campaign-name-tooltip'>
              <span>{dashboardTooltips.campaignName ? dashboardTooltips.campaignName.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.dateCreated ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='date-created-tooltip'>
              <span>{dashboardTooltips.dateCreated ? dashboardTooltips.dateCreated.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.campaignStart ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='campaign-start-tooltip'>
              <span>{dashboardTooltips.campaignStart ? dashboardTooltips.campaignStart.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.campaignEnd ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='campaign-end-tooltip'>
              <span>{dashboardTooltips.campaignEnd ? dashboardTooltips.campaignEnd.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.sales ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='sales-tooltip'>
              <span>{dashboardTooltips.sales ? dashboardTooltips.sales.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.campaignCompletion ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='campaign-completetion-tooltip'>
              <span>{dashboardTooltips.campaignCompletion ? dashboardTooltips.campaignCompletion.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.redemptions ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='redemptions-tooltip'>
              <span>{dashboardTooltips.redemptions ? dashboardTooltips.redemptions.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.gifted ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='gifted-tooltip'>
              <span>{dashboardTooltips.gifted ? dashboardTooltips.gifted.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.customerPayment ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='customer-payment-tooltip'>
              <span>{dashboardTooltips.customerPayment ? dashboardTooltips.customerPayment.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.commission ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='commission-tooltip'>
              <span>{dashboardTooltips.commission ? dashboardTooltips.commission.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {/* {dashboardTooltips.companyPayment ? (
          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='company-payment-tooltip'>
            <span>{dashboardTooltips.companyPayment ? dashboardTooltips.companyPayment.value : ''}</span>
          </ReactTooltip>
        ):(null)} */}

          {dashboardTooltips.earnings ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='earnings-tooltip'>
              <span>{dashboardTooltips.earnings ? dashboardTooltips.earnings.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.preview ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='preview-tooltip'>
              <span>{dashboardTooltips.preview ? dashboardTooltips.preview.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          {dashboardTooltips.campaignDetails ? (
            <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='details-tooltip'>
              <span>{dashboardTooltips.campaignDetails ? dashboardTooltips.campaignDetails.value : ''}</span>
            </ReactTooltip>
          ) : (null)}

          <Button color="primary" className="company-dashboard-campaigns-table-toggle" onClick={this.toggleTableSize} >
            {tableHeight === 220 ? '\u23F7' : '\u23F6'}
          </Button>

          <Table
            style={{ fontSize: 14 }}
            sortType={sortType}
            sortColumn={sortColumn}
            data={vouchersArr}
            className="company-dashboard-campaigns-table"
            virtualized={true}
            minHeight={tableHeight}
            height={tableHeight}
            onSortColumn={this.sort}
            rowKey='uid'
            headerHeight={70}
            rowHeight={50}
          >
            <Column width={170} resizable sortable>
              <HeaderCell renderSortIcon={(sortType) => (<CustomSortIcons sortType={sortType} />)}><div className="display-inline" data-tip data-for='status-tooltip'> Status </div></HeaderCell>
              <Cell dataKey="status">
                {(rowData, rowIndex) => {
                  return (
                    <div>
                      {rowData.status === 1 ? 'Live' : null}
                      {(rowData.status === 8) ? '-' : null}
                      {(rowData.status === 2) ? 'Paused new' : null}
                      {(rowData.status === 3) ? (rowData.connectionError ? 'Connection error' : 'Paused all') : null}
                      {(rowData.status === 4) ? 'Expired (stopped)' : null}
                      {(rowData.status === 5) ? 'Expired (completed)' : null}
                      {(rowData.status === 6) ? 'Expired (denied)' : null}
                      {(rowData.status === 7) ? 'Connection error' : null}
                    </div>
                  )
                }}
              </Cell>
            </Column>
            <Column width={240} resizable>
              <HeaderCell><div className="display-inline" data-tip data-for='request-tooltip'> Request </div></HeaderCell>
              <Cell dataKey="status">
                {(rowData, rowIndex) => {
                  return (
                    <div>
                      {rowData.status === 8 ? (
                        <div>
                          Requesting campaign
                          <Button disabled={showNoPermission} color="link" style={{ marginLeft: 5 }} className="basic-information-changes-cancel-button" onClick={() => this.cancelCampaignRequest(rowData.id)}>
                            <div className="basic-information-changes-cancel">
                              x
                            </div>
                          </Button>
                          {buttonPressed === rowData.id ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                        </div>
                      ) : null}
                      {rowData.status < 4 && rowData.hasPendingChanges ? (
                        <>
                          {(rowData.voucherPendingChanges && rowData.voucherPendingChanges.status === 4) ? (
                            <div> Requesting stop </div>
                          ) : (null)}
                          {(rowData.companyPendingChanges || (rowData.voucherPendingChanges && !(rowData.voucherPendingChanges.status === 4 && Object.keys(rowData.voucherPendingChanges).length === 1))) ? (
                            <div> Requesting change </div>
                          ) : (null)}
                        </>
                      ) : null}
                      {(!(rowData.status === 8 || (rowData.status < 4 && rowData.hasPendingChanges)) ? '-' : null)}
                    </div>
                  )
                }}
              </Cell>
            </Column>
            <Column width={220} sortable resizable>
              <HeaderCell><div className="display-inline" data-tip data-for='campaign-name-tooltip'> Campaign Name </div></HeaderCell>
              <Cell dataKey="nameLower">
                {(rowData, rowIndex) => {
                  return (
                    <div>
                      {rowData.name ? (rowData.name) : (null)}
                    </div>
                  )
                }}
              </Cell>
            </Column>

            <Column width={160} sortable resizable>
              <HeaderCell><div className="display-inline" data-tip data-for='campaign-name-tooltip'> Share </div></HeaderCell>
              <Cell dataKey="nameLower">
                {(rowData, rowIndex) => {
                  if (rowData.status === 1) {
                    return (
                      <Button color="link" onClick={(e) => this.copyCampaignLink(rowData.id)}>
                        Copy
                      </Button>
                    )
                  }
                  return (
                    <div>-</div>
                  )
                }}
              </Cell>
            </Column>

            <Column width={160} sortable resizable>
              <HeaderCell><div className="display-inline" data-tip data-for='date-created-tooltip'> Date Created </div></HeaderCell>
              <Cell dataKey="created_at">
                {(rowData, rowIndex) => {
                  return (
                    <div>
                      {rowData.created_at ? (
                        <Moment format="DD-MM-YYYY" locale="=uk">
                          {rowData.created_at.seconds * 1000}
                        </Moment>
                      ) : (null)}
                    </div>
                  )
                }}
              </Cell>
            </Column>

            <Column width={140} sortable resizable>
              <HeaderCell><div className="display-inline" data-tip data-for='campaign-start-tooltip'> Campaign Start </div></HeaderCell>
              <Cell dataKey="startDate" >
                {(rowData, rowIndex) => {
                  return (
                    <>
                      {rowData.startDate ? (
                        <Moment format="DD-MM-YYYY" locale="lv">
                          {rowData.startDate.seconds * 1000}
                        </Moment>
                      ) : (null)}
                    </>
                  )
                }}
              </Cell>
            </Column>
            <Column width={140} sortable resizable>
              <HeaderCell><div className="display-inline" data-tip data-for='campaign-end-tooltip'> Campaign End </div></HeaderCell>
              <Cell dataKey="endDate" >
                {(rowData, rowIndex) => {
                  return (
                    <>
                      {(rowData.endDate && rowData.endDate.seconds && rowData.endDate.seconds < 28375023200) ? (
                        <Moment format="DD-MM-YYYY" locale="lv">
                          {rowData.endDate.seconds * 1000}
                        </Moment>
                      ) : ('Ongoing')}
                    </>
                  )
                }}
              </Cell>
            </Column>
            <ColumnGroup header={<div data-tip data-for='sales-tooltip'> Face Value Sales </div>}>
              <Column width={110} sortable resizable>
                <HeaderCell>#</HeaderCell>
                <Cell dataKey="salesCount">
                  {(rowData, rowIndex) => (
                    <div> {rowData.salesCount ? formattedNumber(rowData.salesCount, 0) : '-'} </div>
                  )}
                </Cell>
              </Column>
              <Column width={130} sortable resizable>
                <HeaderCell>£</HeaderCell>
                <Cell dataKey="salesValue">
                  {(rowData, rowIndex) => {
                    if (rowData.salesValue) {
                      if (rowData.applicationFeeValue && false) {
                        return (
                          <div> {rowData.salesValue ? formattedNumber(Math.round((rowData.salesValue - ((rowData.applicationFeeValue / 100))) * 100) / 100, 2) : '-'} </div>
                        )
                      }
                      return (
                        <div> {rowData.salesValue ? formattedNumber(rowData.salesValue, 2) : '-'} </div>
                      )
                    }
                    return (
                      <div> {'-'} </div>
                    )
                  }}
                </Cell>
              </Column>
            </ColumnGroup>
            <ColumnGroup header={<div data-tip data-for='campaign-completetion-tooltip'> Campaign Completion (%) </div>}>
              <Column width={110} sortable resizable>
                <HeaderCell>Step 1</HeaderCell>
                <Cell dataKey="step1SalesPerc">
                  {/* <div> {`${rowData.step1Sales ? (Math.round(rowData.step1Sales / rowData.totalValue * 100) / 100) : 0}%`} </div> */}
                  {(rowData, rowIndex) => (
                    <div>{(rowData.step1SalesPerc && rowData.step1SalesPerc !== '0.00') ? `${rowData.step1SalesPerc}%` : '-'} </div>
                  )}
                </Cell>
              </Column>
              <Column width={130} sortable resizable>
                <HeaderCell>Step 2</HeaderCell>
                <Cell dataKey="step2SalesPerc">
                  {(rowData, rowIndex) => (
                    <div> {(rowData.step2SalesPerc && rowData.step2SalesPerc !== '0.00') ? `${rowData.step2SalesPerc}%` : '-'} </div>
                  )}
                </Cell>
              </Column>
              <Column width={130} sortable resizable>
                <HeaderCell>Step 3</HeaderCell>
                <Cell dataKey="step3SalesPerc">
                  {(rowData, rowIndex) => (
                    <div> {(rowData.step3SalesPerc && rowData.step3SalesPerc !== '0.00') ? `${rowData.step3SalesPerc}%` : '-'} </div>
                  )}
                </Cell>
              </Column>
              <Column width={130} sortable resizable>
                <HeaderCell>Total</HeaderCell>
                <Cell dataKey="salesValuePerc">
                  {(rowData, rowIndex) => (
                    <div> {(rowData.salesValuePerc && rowData.salesValuePerc !== '0.00') ? `${rowData.salesValuePerc}%` : '-'} </div>
                  )}
                </Cell>
              </Column>
            </ColumnGroup>
            <ColumnGroup header={<div data-tip data-for='redemptions-tooltip'> Redemptions </div>}>
              <Column width={110} sortable resizable>
                <HeaderCell>#</HeaderCell>
                <Cell dataKey="redemptionCount">
                  {(rowData, rowIndex) => (
                    <>
                      <div> {rowData.redemptionCount ? formattedNumber(rowData.redemptionCount, 0) : '-'} </div>
                      {/*  <div> {companyPaymentsTotalsPerCampaign[rowData.id] ? formattedNumber(companyPaymentsTotalsPerCampaign[rowData.id].redeemed) : '-'} </div> */}
                    </>
                  )}
                </Cell>
              </Column>
              <Column width={110} sortable resizable>
                <HeaderCell>£</HeaderCell>
                <Cell dataKey="redemptionValue">
                  {(rowData, rowIndex) => (
                    <>
                      <div> {rowData.redemptionValue ? formattedNumber(rowData.redemptionValue, 2) : '-'} </div>
                      {/*  <div> {companyPaymentsTotalsPerCampaign[rowData.id] ? formattedNumber(companyPaymentsTotalsPerCampaign[rowData.id].redeemedValue) : '-'} </div> */}
                    </>
                  )}
                </Cell>
              </Column>
            </ColumnGroup>
            {/*  <ColumnGroup header={<div data-tip data-for='gifted-tooltip'> Gifted </div>}>
            <Column width={110} sortable resizable>
              <HeaderCell>#</HeaderCell>
              <Cell dataKey="giftedCount">
                {(rowData, rowIndex) => (
                  <>
                      <div> {rowData.giftedCount ? formattedNumber(rowData.giftedCount, 0) : '-'} </div>
                  </>
                )}
              </Cell>
            </Column>
            <Column width={110} sortable resizable>
              <HeaderCell>£</HeaderCell>
              <Cell dataKey="giftedValue">
                {(rowData, rowIndex) => (
                  <>
                      <div> {rowData.giftedValue ? formattedNumber(rowData.giftedValue, 2) : '-'} </div>
                  </>
                )}
              </Cell>
            </Column>
          </ColumnGroup>  */}

            <Column width={220} sortable resizable>
              <HeaderCell><div data-tip data-for='customer-payment-tooltip' style={{ display: 'inline-block' }}>Voucher Payments (£)</div></HeaderCell>
              <Cell dataKey="customerSales">
                {(rowData, rowIndex) => (
                  <>
                    <div> {rowData.customerSales ? formattedNumber(rowData.customerSales / 100, 2) : '-'} </div>
                    {/*  <div> {rowData.salesValue ? formattedNumber(Math.round((rowData.salesValue - ((rowData.applicationFeeValue / 100))) * 100) / 100) : 0} </div> */}
                    {/*  <div> {companyPaymentsTotalsPerCampaign[rowData.id] ? formattedNumber((companyPaymentsTotalsPerCampaign[rowData.id].amountCaptured / 100).toFixed(2)) : 0} </div> */}
                  </>
                )}
              </Cell>
            </Column>
            {(company && company.showCommission) ? (
              <Column width={170} sortable resizable>
                <HeaderCell><div data-tip data-for='commission-tooltip'>Commission (%)</div></HeaderCell>
                <Cell dataKey="commission">
                  {(rowData, rowIndex) => (
                    <>
                      <div> {rowData.commission ? `${(rowData.commission)}%` : `-%`} </div>
                    </>
                  )}
                </Cell>
              </Column>
            ) : (null)}
            {/* <Column width={170} resizable>
            <HeaderCell><div data-tip data-for='company-payment-tooltip'>Company Payment (£)</div></HeaderCell>
            <Cell dataKey="customerSales">
              {(rowData, rowIndex) => (
                <>
                  <div> {rowData.customerSales ? formattedNumber(Math.round(((rowData.customerSales / 100) - ((rowData.applicationFeeValue / 100))) * 100) / 100, 2) : `0.00`} </div>
                </>
              )}
            </Cell>
          </Column> */}

            <Column width={130} resizable>
              <HeaderCell><div data-tip data-for='preview-tooltip'> Preview </div></HeaderCell>
              <Cell dataKey="button preview">
                {(rowData, rowIndex) => (
                  <Button color="link" style={{ paddingTop: 0 }} onClick={(e) => this.openPreview(e, rowData)}>
                    Preview
                  </Button>
                )}
              </Cell>
            </Column>
            <Column width={130} resizable>
              <HeaderCell><div data-tip data-for='details-tooltip'> Campaign Details </div></HeaderCell>
              <Cell dataKey="button edit">
                {(rowData, rowIndex) => (
                  <Button color="link" style={{ paddingTop: 0 }} onClick={(e) => this.openCampaignDetails(e, rowData)}>
                    Details
                  </Button>
                )}
              </Cell>
            </Column>

          </Table>
        </div>

        {campaignDetailsId ? (
          <CampaignDetails
            showNoPermission={showNoPermission}
            campaignDetails={campaignDetails}
            campaignDetailsId={campaignDetailsId}
            close={this.closeCampaignDetails}
            reload={this.reloadCampaignDetails}
          />
        ) : (null)}

        <div style={{ textAlign: 'right', color: '#6d6d6d' }}>
          Values, which update each minute, are only indicative and may change.
        </div>

        <Modal isOpen={previewModal} toggle={this.closePreview} className="voucher-preview-modal">
          <ModalBody>
            <div className="voucher-preview">
              <Row className="voucher-preview-app-top">
                <Col xs="4" className="voucher-preview-app-top-logo-wrapper">
                  {(branding && branding.logo) ? <img className="voucher-preview-app-top-logo" src={branding.logo} alt="" /> : null}
                  <div className="voucher-preview-app-top-logo-text">
                    Steppr
                  </div>
                </Col>
                <Col xs="3" className="voucher-preview-app-top-middle">
                  <div className="voucher-preview-app-top-middle-icon">
                    {'\u2039'}
                  </div>
                </Col>
                <Col xs="5" className="voucher-preview-app-top-right">
                  <div className="voucher-preview-app-top-right-icon">
                    <img src={bell} alt="" />
                  </div>
                  <div className="voucher-preview-app-top-right-icon">
                    <img src={gear} alt="" />
                  </div>
                </Col>
              </Row>
              <ScrollArea
                style={{ maxHeight: 500 }}
                speed={0.6}
                smoothScrolling={true}
                className="area"
                contentClassName="content"
                horizontal={false}
                ref={(el) => { this.previewScrollbar = el; }}
              >
                <div className="voucher-preview-top">
                  <Media className="voucher-preview-top-image" src={company.companyPrimaryVoucherImage || defaultPrimary} />
                </div>

                <div className="voucher-preview-header">
                  {(company.logoImage || company.name) ? (
                    <>
                      <Media className="voucher-preview-header-logo" src={company.logoImage || defaultLogo} />
                      <div className="voucher-preview-header-text">
                        <div className="voucher-preview-header-name">
                          {company.name}
                        </div>
                        <div className="voucher-preview-header-website">
                          {company.website ? company.website.replace(/(^\w+:|^)\/\//, '') : ''}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="voucher-preview-body">
                  <div className={readMore ? 'voucher-preview-body-description' : 'voucher-preview-body-description-hidden'}>
                    {company.description}
                  </div>
                  <Button className="voucher-preview-body-more" color="link" onClick={this.toggleReadMore}>
                    {readMore ? 'show less' : 'read more'}
                  </Button>
                </div>
                <div className="voucher-preview-loyalty-text"> Loyalty steps </div>
                <div className="voucher-preview-footer">
                  <div className="voucher-preview-steps">
                    <Media className="voucher-preview-steps-image" src={line} />
                    <div className={`voucher-preview-steps-1 ${previewStep === '1' ? 'voucher-preview-steps-active' : ''}`} onClick={() => this.togglePreviewStep('1')}>
                      <div className="voucher-preview-steps-percent">
                        {(discount && discount['1']) ? `${discount['1']}%` : '0%'}
                      </div>
                      <div className="voucher-preview-steps-name">
                        Step 1
                      </div>
                    </div>
                    <div className={`voucher-preview-steps-2 ${previewStep === '2' ? 'voucher-preview-steps-active' : ''}`} onClick={() => this.togglePreviewStep('2')}>
                      <div className="voucher-preview-steps-percent">
                        {(discount && discount['2']) ? `${discount['2']}%` : '0%'}
                      </div>
                      <div className="voucher-preview-steps-name">
                        Step 2
                      </div>
                    </div>
                    <div className={`voucher-preview-steps-3 ${previewStep === '3' ? 'voucher-preview-steps-active' : ''}`} onClick={() => this.togglePreviewStep('3')}>
                      <div className="voucher-preview-steps-percent">
                        {(discount && discount['3']) ? `${discount['3']}%` : '0%'}
                      </div>
                      <div className="voucher-preview-steps-name">
                        Step 3
                      </div>
                    </div>
                  </div>

                  <div className="voucher-preview-vouchers">
                    <div className="voucher-preview-voucher">
                      <div className="voucher-preview-vouchers-left">
                        <div className="voucher-preview-vouchers-left-1">
                          {`£${(availableVouchers && availableVouchers['0']) ? availableVouchers['0'] : 0}`}
                        </div>
                        <div className="voucher-preview-vouchers-left-2">
                          VOUCHER
                        </div>
                      </div>
                      <div className="voucher-preview-vouchers-right">
                        {previewStep === '1' ? (
                          <div className="voucher-preview-vouchers-right-1">
                            {'BUY FOR'}
                          </div>
                        ) : (
                          <div className="voucher-preview-vouchers-right-1-long">
                            {previewStep === '2' ? (
                              'BUY STEP 1 TO UNLOCK'
                            ) : (
                              'BUY STEP 2 TO UNLOCK'
                            )}
                          </div>
                        )}
                        <div className={`voucher-preview-vouchers-right-2 ${previewStep !== '1' ? 'voucher-preview-vouchers-right-2-long' : ''}`}>
                          {(availableVouchers && discount && availableVouchers['0'] && discount[previewStep]) ? `£${(availableVouchers['0'] - (availableVouchers['0'] * (discount[previewStep] / 100))).toFixed(2)}` : '£0'}
                        </div>
                      </div>
                    </div>
                    <div className="voucher-preview-voucher">
                      <div className="voucher-preview-vouchers-left">
                        <div className="voucher-preview-vouchers-left-1">
                          {`£${(availableVouchers && availableVouchers['1']) ? availableVouchers['1'] : 0}`}
                        </div>
                        <div className="voucher-preview-vouchers-left-2">
                          VOUCHER
                        </div>
                      </div>
                      <div className="voucher-preview-vouchers-right">
                        {previewStep === '1' ? (
                          <div className="voucher-preview-vouchers-right-1">
                            {'BUY FOR'}
                          </div>
                        ) : (
                          <div className="voucher-preview-vouchers-right-1-long">
                            {previewStep === '2' ? (
                              'BUY STEP 1 TO UNLOCK'
                            ) : (
                              'BUY STEP 2 TO UNLOCK'
                            )}
                          </div>
                        )}
                        <div className={`voucher-preview-vouchers-right-2 ${previewStep !== '1' ? 'voucher-preview-vouchers-right-2-long' : ''}`}>
                          {(availableVouchers && discount && availableVouchers['1'] && discount[previewStep]) ? `£${(availableVouchers['1'] - (availableVouchers['1'] * (discount[previewStep] / 100))).toFixed(2)}` : '£0'}
                        </div>
                      </div>
                    </div>
                    <div className="voucher-preview-voucher">
                      <div className="voucher-preview-vouchers-left">
                        <div className="voucher-preview-vouchers-left-1">
                          {`£${(availableVouchers && availableVouchers['2']) ? availableVouchers['2'] : 0}`}
                        </div>
                        <div className="voucher-preview-vouchers-left-2">
                          VOUCHER
                        </div>
                      </div>
                      <div className="voucher-preview-vouchers-right">
                        {previewStep === '1' ? (
                          <div className="voucher-preview-vouchers-right-1">
                            {'BUY FOR'}
                          </div>
                        ) : (
                          <div className="voucher-preview-vouchers-right-1-long">
                            {previewStep === '2' ? (
                              'BUY STEP 1 TO UNLOCK'
                            ) : (
                              'BUY STEP 2 TO UNLOCK'
                            )}
                          </div>
                        )}
                        <div className={`voucher-preview-vouchers-right-2 ${previewStep !== '1' ? 'voucher-preview-vouchers-right-2-long' : ''}`}>
                          {(availableVouchers && discount && availableVouchers['2'] && discount[previewStep]) ? `£${(availableVouchers['2'] - (availableVouchers['2'] * (discount[previewStep] / 100))).toFixed(2)}` : '£0'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollArea>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || {},
  vouchers: state.vouchers.vouchers || {},
  vouchersArr: state.vouchers.vouchersArr || [],
  roles: state.users.companiesRoles || {},
  company: state.company.company || {},
  companyPaymentsTotals: state.company.companyPaymentsTotals || {},
  companyPaymentsTotalsPerCampaign: state.company.companyPaymentsTotalsPerCampaign || {},
  branding: state.contentManagement.branding || {},
});

const mapDispatchToProps = {
  fetchCompanyChanges: getCompanyChanges,
  sortVouchers: sortCompanyVouchers,
  cancelRequest: cancelCampaignRequest,
  getVouchers: getCompanyVouchers,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VouchersList));
