import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardBody, Row, Col, Button, Input, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Alert, Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';

import { Eye, EyeOff } from 'react-feather';

import ReactTooltip from 'react-tooltip';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';

import htmlToDraft from 'html-to-draftjs';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getCompanyApiKey,
  changeCompanyApiKey,
  getShopifyAPIData,
  changeShopifyAPIData,
  getWoocommerceAPIData,
  changeWoocommerceAPIData,
  getManualAPIData,
  changeManualAPIData,
  getActiveAPIData,
  changeActiveAPIData,

  getLinkedAPIs,
  updateLinkedAPI,
  addLinkedAPI,
  removeLinkedAPI,
} from '../../../actions/company';

import {
  getAPIPageData,
  getAPITooltips,
} from '../../../actions/contentManagement';

import notifications from '../../../const/notifications';

import LinkedCompaniesAPIs from './API/LinkedCompaniesAPIs';

class Api extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    contactPage: PropTypes.shape(),
    apiTooltips: PropTypes.shape(),
    fetchAPITooltips: PropTypes.func.isRequired,
    fetchContactPageData: PropTypes.func.isRequired,
    getShopifyAPI: PropTypes.func.isRequired,
    changeShopifyAPI: PropTypes.func.isRequired,
    getWoocommerceAPI: PropTypes.func.isRequired,
    getManualAPI: PropTypes.func.isRequired,
    changeWoocommerceAPI: PropTypes.func.isRequired,
    getActiveAPI: PropTypes.func.isRequired,
    changeActiveAPI: PropTypes.func.isRequired,
    fetchLinkedAPIs: PropTypes.func.isRequired,
    linkedAPIUpdate: PropTypes.func.isRequired,
    linkedAPIAdd: PropTypes.func.isRequired,
    linkedAPIRemove: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    contactPage: {},
    apiTooltips: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      copiedSuccess: false,
      show: false,
      show2: false,
      showNoPermission: false,
      dontShowPage: false,
      hadExistingData: false,
      showConfirmModal: false,
      showConfirmModalShown: false,
      originalShopify: {},
      originalWoocommerce: {},
      hasOriginal: false,
    };
  }

  componentDidMount() {
    const { adminViewAsCompany, fetchCompanyApiKey, fetchAPIPageData, user, getShopifyAPI, getWoocommerceAPI, getManualAPI, fetchAPITooltips, getActiveAPI, fetchLinkedAPIs, linkedCompaniesAccount } = this.props;

    fetchAPITooltips();
    fetchAPIPageData().then((res) => {
      if (res) {
        //  let contentState = stateFromHTML(res);

        const blocksFromHtml = htmlToDraft(res);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          this.setState({ editorState: EditorState.createWithContent(contentState) });
        }
      }
    });

    if (user && user.company) {
      fetchCompanyApiKey(user.company);
      getShopifyAPI(user.company).then((res) => {
        if (res && res.payload && res.payload.accessData) {
          const APIData = res.payload.accessData || {};

          console.log('APIData', APIData);

          this.setState({
            hadExistingData: true,
            shopifyAPIAccessToken: APIData.accessToken,
            shopifyAPIShopName: APIData.shopName,
            originalShopify: {
              shopifyAPIAccessToken: APIData.accessToken, shopifyAPIShopName: APIData.shopName,
            },
            hasOriginal: true,
          });
        } else {
          this.setState({ hadExistingData: false, shopifyAPIAccessToken: null, shopifyAPIShopName: null, originalShopify: {} });
        }
      });

      getWoocommerceAPI(user.company).then((res) => {
        if (res && res.payload && res.payload.accessData) {
          const APIData = res.payload.accessData || {};

          this.setState({
            hadExistingData: true,
            woocommerceConsumerKey: APIData.consumerKey,
            woocommerceConsumerSecret: APIData.consumerSecret,
            shopUrl: APIData.woocommerceShopName,
            originalWoocommerce: {
              woocommerceConsumerKey: APIData.consumerKey, woocommerceConsumerSecret: APIData.consumerSecret, shopUrl: APIData.woocommerceShopName
            },
            hasOriginal: true,
          });
        } else {
          this.setState({ hadExistingData: false, woocommerceConsumerKey: null, woocommerceConsumerSecret: null, originalWoocommerce: {} });
        }
      });

      getManualAPI(user.company).then((res) => {
        if (res && res.payload) {
          this.setState({
            hadExistingData: true,
            hasOriginal: true,
          });
        } else {
          this.setState({ hadExistingData: false });
        }
      });

      getActiveAPI(user.company);
      if (!linkedCompaniesAccount && !user.linkedCompaniesAccount) {
        
      } else {
      //  fetchLinkedAPIs(user.company);
      }
    }

    let showPageAdmin = false;

    if (adminViewAsCompany && user.roleClass === 'admin') {
      if (user.role === 'admin') {
        this.setState({ showNoPermission: false, dontShowPage: false });
        showPageAdmin = true;
      } else {
        const { adminsRoles } = this.props;

        if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.companyAccess) {
          this.setState({ showNoPermission: false, dontShowPage: false });
          showPageAdmin = true;
        } else {
          this.setState({ showNoPermission: false, dontShowPage: true });
        }
      }

      if (showPageAdmin && adminViewAsCompany) {
        fetchCompanyApiKey(adminViewAsCompany);
        getShopifyAPI(adminViewAsCompany).then((res) => {
          if (res && res.payload && res.payload.accessData) {
            const APIData = res.payload.accessData || {};

            console.log('APIData', APIData);

            this.setState({
              hadExistingData: true,
              shopifyAPIAccessToken: APIData.accessToken,
              shopifyAPIShopName: APIData.shopName,
              originalShopify: {
                shopifyAPIAccessToken: APIData.accessToken, shopifyAPIShopName: APIData.shopName,
              },
              hasOriginal: true,
            });
          } else {
            this.setState({ hadExistingData: false, shopifyAPIAccessToken: null, shopifyAPIShopName: null, originalShopify: {} });
          }
        });

        getWoocommerceAPI(adminViewAsCompany).then((res) => {
          if (res && res.payload && res.payload.accessData) {
            const APIData = res.payload.accessData || {};

            this.setState({
              hadExistingData: true,
              woocommerceConsumerKey: APIData.consumerKey,
              woocommerceConsumerSecret: APIData.consumerSecret,
              shopUrl: APIData.woocommerceShopName,
              originalWoocommerce: {
                woocommerceConsumerKey: APIData.consumerKey, woocommerceConsumerSecret: APIData.consumerSecret, shopUrl: APIData.woocommerceShopName
              },
              hasOriginal: true,
            });
          } else {
            this.setState({ hadExistingData: false, woocommerceConsumerKey: null, woocommerceConsumerSecret: null, originalWoocommerce: {} });
          }
        });

        getManualAPI(adminViewAsCompany).then((res) => {
          console.log("getManualAPI res", res);
          if (res && res.payload) {
            this.setState({
              hadExistingData: true,
              hasOriginal: true,
            });
          } else {
            this.setState({ hadExistingData: false });
          }
        });

        getActiveAPI(adminViewAsCompany);
      }

    } else if (user.company) {
      const { roles } = this.props;

      if (user.role === 'teamOwner') {
        this.setState({ showNoPermission: false });
      } else if (user.role === 'linkedCompanyUser') {
        this.setState({ showNoPermission: true, dontShowPage: true });
      } else {
        if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.api) {
          if (roles[user.role].permissions.api === 2) {
            this.setState({ showNoPermission: false, dontShowPage: false });
          } else {
            this.setState({ showNoPermission: false, dontShowPage: false });
          }
        } else {
          this.setState({ showNoPermission: false, dontShowPage: true });
        }
      }
    }
  }

  requestApiKeyChange = () => {
    const { user, changeApiKey } = this.props;

    changeApiKey(user.company);
  }

  copyToClipboard = () => {
    const { apiKey } = this.props;

    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      this.setState({ copiedSuccess: true });
    }
  }

  toggleShow = () => {
    this.setState((prevState) => ({ show: !prevState.show }));
  }

  toggleShow2 = () => {
    this.setState((prevState) => ({ show2: !prevState.show2 }));
  }


  handleChangeAPI = (e) => {
    e.preventDefault();
    const { showConfirmModalShown, hadExistingData } = this.state;

    if (hadExistingData && !showConfirmModalShown) {
      this.setState({ showConfirmModalShown: true, showConfirmModal: true });
    }

    if (e.target && e.target.name) {
      const { name, value } = e.target;

      this.setState({ [name]: value, changeAPIError: false });
    }
  }

  isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    if (string.match(/^(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/)) {
      console.log('isValidHttpUrl');
      return url.protocol === "http:" || url.protocol === "https:";
    } else {
      console.log('isValidHttpUrl false');
      return false;
    }
  }

  changeShopifyAPI = () => {
    const { changeShopifyAPI, user, adminViewAsCompany, companyName } = this.props;
    const { shopifyAPIAccessToken, shopifyAPIShopName } = this.state;

    console.log('changeShopifyAPI', shopifyAPIAccessToken, shopifyAPIShopName);

    console.log('companyName', companyName, user);

    this.setState({ processing: true });

    let shopifyName = null;

    if (shopifyAPIShopName) {
      shopifyName = shopifyAPIShopName.replace(/\s/g, "");

      if (shopifyName.startsWith('www.') || shopifyName.startsWith('http')) {
        this.setState({ processing: false, changeAPIError: notifications.company.websiteConnection.wrongFormat || 'Wrong format' });
        return null;
      }

      if (shopifyName.endsWith('/')) {
        this.setState({ processing: false, changeAPIError: notifications.company.websiteConnection.wrongFormat || 'Wrong format' });
        return null;
      }
    } else {
      this.setState({ processing: false, changeAPIError: notifications.company.websiteConnection.noWebAdress || 'No web address' });
    }

    if (!shopifyAPIAccessToken) {
      this.setState({ processing: false, changeAPIError: notifications.company.websiteConnection.noAccessToken || 'No access token' });
    }

    console.log('changeShopifyAPI', adminViewAsCompany, user.company, shopifyAPIAccessToken, shopifyName, companyName)

    changeShopifyAPI(adminViewAsCompany || user.company, shopifyAPIAccessToken, shopifyName, (companyName || user.companyName || (adminViewAsCompany ? 'admin' : null))).then((res) => {
      if (res === 'missing data') {
        this.setState({ processing: false, changeAPIError: notifications.company.websiteConnection.missingData || 'Missing data' });
      } else {
        setTimeout(() => {
          this.setState({ processing: false, changeAPIError: null, hasOriginal: true });
        }, 250);
      }

    }).catch(err => {
      console.log(err);

      setTimeout(() => {
        this.setState({ processing: false, changeAPIError: err });
      }, 400);
    });
  }

  changeWoocommerceAPI = () => {
    const { changeWoocommerceAPI, user, adminViewAsCompany, companyName } = this.props;
    const { woocommerceConsumerKey, woocommerceConsumerSecret, shopUrl } = this.state;

    console.log('companyName', companyName, user);

    this.setState({ processing: true });

    let shopifyName = null;

    if (!woocommerceConsumerSecret) {
      this.setState({ processing: false, changeAPIError: notifications.company.websiteConnection.noConsumerSecret || 'No ConsumerSecret' });
    }

    if (!woocommerceConsumerKey) {
      this.setState({ processing: false, changeAPIError: notifications.company.websiteConnection.noConsumerKey || 'No ConsumerKey' });
    }

    if (!shopUrl) {
      this.setState({ processing: false, changeAPIError: notifications.company.websiteConnection.shopUrl || 'No shopUrl' });
    }

    changeWoocommerceAPI(adminViewAsCompany || user.company, woocommerceConsumerKey, woocommerceConsumerSecret, shopUrl, (companyName || user.companyName || (adminViewAsCompany ? 'admin' : null))).then((res) => {
      if (res === 'missing data') {
        this.setState({ processing: false, changeAPIError: notifications.company.websiteConnection.missingData || 'Missing data' });
      } else {
        setTimeout(() => {
          this.setState({ processing: false, changeAPIError: null, hasOriginal: true });
        }, 250);
      }

    }).catch(err => {
      console.log(err);

      setTimeout(() => {
        this.setState({ processing: false, changeAPIError: err });
      }, 400);
    });
  }

  changeManualAPI = () => {
    const { changeManualAPI, user, adminViewAsCompany, companyName } = this.props;
    const { } = this.state;

    console.log('companyName', companyName, user);

    this.setState({ processing: true });

    changeManualAPI(adminViewAsCompany || user.company, (companyName || user.companyName || (adminViewAsCompany ? 'admin' : null))).then((res) => {
      if (res === 'missing data') {
        this.setState({ processing: false, changeAPIError: notifications.company.websiteConnection.missingData || 'Missing data' });
      } else {
        setTimeout(() => {
          this.setState({ processing: false, changeAPIError: null, hasOriginal: true });
        }, 250);
      }

    }).catch(err => {
      console.log(err);

      setTimeout(() => {
        this.setState({ processing: false, changeAPIError: err });
      }, 400);
    });
  }

  toggle = (APIType) => {
    const { changeActiveAPI, user, adminViewAsCompany } = this.props;
    const { originalShopify, originalWoocommerce } = this.state;

    console.log('toggle', originalShopify, originalWoocommerce)

    try {
      if (originalShopify.shopifyAPIAccessToken || originalShopify.shopifyAPIShopName || originalWoocommerce.woocommerceConsumerKey || originalWoocommerce.woocommerceConsumerSecret || originalWoocommerce.shopUrl) {
        return null;
      }

      if (adminViewAsCompany) {
        changeActiveAPI(adminViewAsCompany, APIType);
      } else {
        changeActiveAPI(user.company, APIType);
      }
    } catch (err) {
      console.log('err', err);
    }

  }

  approveConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  }

  closeConfirmModal = () => {
    const { original, activeAPI } = this.state;

    if (activeAPI === 'woocommerce') {
      this.setState({
        showConfirmModal: false,
        showConfirmModalShown: false,
        woocommerceConsumerKey: original.woocommerceConsumerKey || '',
        woocommerceConsumerSecret: original.woocommerceConsumerSecret || '',
        shopUrl: original.shopUrl || '',
      });
    } else {
      this.setState({
        showConfirmModal: false,
        showConfirmModalShown: false,
        shopifyAPIAccessToken: original.shopifyAPIAccessToken || '',
        shopifyAPIShopName: original.shopifyAPIShopName || '',
      });
    }

  }

  render() {
    const {
      apiKey,
      apiTooltips,
      activeAPI,
      adminViewAsCompany,
      user,
      linkedCompaniesAPIs,
      linkedAPIUpdate,
      linkedAPIAdd,
      linkedAPIRemove,
      linkedCompaniesAccount,
    } = this.props;

    const {
      show,
      show2,
      editorState,
      showNoPermission,
      dontShowPage,
      shopifyAPIAccessToken,
      shopifyAPIShopName,
      showConfirmModal,
    //  processingModal,
    //  processingConfirm,
      processing,
      changeAPIError,
      woocommerceConsumerKey,
      woocommerceConsumerSecret,
      shopUrl,
      hasOriginal,
    } = this.state;

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view website connections
            </CardBody>
          </Card>
        </div>
      );
    }

    if (linkedCompaniesAccount || user.linkedCompaniesAccount) {
      return (
        <LinkedCompaniesAPIs
          user={user}
          linkedCompaniesAPIs={linkedCompaniesAPIs}
          linkedAPIUpdate={linkedAPIUpdate}
          linkedAPIAdd={linkedAPIAdd}
          linkedAPIRemove={linkedAPIRemove}
        />
      );
    }

    return (
      <Card>
        <CardBody>
          <div className="company-api">
            <Row>
              <Col xs="12">
                <Button
                  color={`${activeAPI === 'shopify' ? 'primary' : 'secondary'}`}
                  className={`${activeAPI === 'shopify' ? 'active' : null}`}
                  onClick={() => { this.toggle('shopify'); }}
                  disabled={hasOriginal}
                  className={`company-tab-change-button ${activeAPI === 'shopify' ? 'company-tab-change-button-active' : null}`}
                >
                  Shopify
                </Button>
                <Button
                  color={`${activeAPI === 'woocommerce' ? 'primary' : 'secondary'}`}
                  className={`${activeAPI === 'woocommerce' ? 'active' : null}`}
                  onClick={() => { this.toggle('woocommerce'); }}
                  disabled={hasOriginal}
                  className={`company-tab-change-button ${activeAPI === 'woocommerce' ? 'company-tab-change-button-active' : null}`}
                >
                  WooCommerce
                </Button>
                <Button
                  color={`${activeAPI === 'manual' ? 'primary' : 'secondary'}`}
                  className={`${activeAPI === 'manual' ? 'active' : null}`}
                  onClick={() => { this.toggle('manual'); }}
                  disabled={hasOriginal}
                  className={`company-tab-change-button ${activeAPI === 'manual' ? 'company-tab-change-button-active' : null}`}
                >
                  Manual
                </Button>
              </Col>
            </Row>
            <Row style={{ height: 40, paddingTop: 15 }}>
              <Col>
                {activeAPI === 'manual' ? (
                  <Label>
                    {/* 
                      <li>For non-website orders (e.g. via email or phone).</li>
                      <li>Customers quote their voucher codes.</li>
                      <li>Check and redeem codes in Payments.</li>
                    </ul> */}
                    Manual redemptions
                  </Label>
                ): (
                  <Label>
                    Your website details
                  </Label>
                )}
              </Col>
            </Row>

            <TabContent activeTab={activeAPI} style={{ padding: 0, background: 'transparent' }}>
              <TabPane tabId="shopify">
                {!!changeAPIError ? (<Alert color="danger"> {changeAPIError} </Alert>) : null}

                <Row style={{ marginTop: 5 }}>
                  <Col>
                    <div className="company-api-input" style={{ position: 'relative', width: '35%', marginRight: 10 }}>

                      <Label for="shopifyAPIAccessToken">
                        Access token<div className="mandatory">*</div>
                      </Label>
                      <Input
                        name="shopifyAPIAccessToken"
                        id="shopifyAPIAccessToken"
                        type={show ? 'text' : 'password'}
                        placeholder=""
                        value={shopifyAPIAccessToken}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeAPI}
                      >
                        {apiKey || null}
                      </Input>
                      {show ? (
                        <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                      ) : (
                        <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                      )}
                    </div>

                    <div className="company-api-input" style={{ width: '35%' }}>
                      <Label for="shopifyAPIShopName">
                        Web address<div className="mandatory">*</div>
                      </Label>
                      <Input
                        name="shopifyAPIShopName"
                        id="shopifyAPIShopName"
                        type="text"
                        placeholder=""
                        value={shopifyAPIShopName}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeAPI}
                      >
                        {apiKey || null}
                      </Input>
                    </div>

                    <Button
                      style={{ top: -2 }}
                      disabled={showNoPermission}
                      className="company-api-button custom-modal-button"
                      color="primary"
                      onClick={this.changeShopifyAPI}
                      data-tip
                      data-for='confirmButton'
                      for="confirmButton"
                    >
                      Confirm
                      {(processing) ? (
                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                      ) : (null)}
                    </Button>

                    <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='confirmButton'>
                      <span>{apiTooltips.apiTabConfirm ? apiTooltips.apiTabConfirm.value : ''}</span>
                    </ReactTooltip>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="woocommerce">
                {!!changeAPIError ? (<Alert color="danger"> {changeAPIError} </Alert>) : null}

                <Row style={{ marginTop: 5 }}>
                  <Col>
                    <div className="company-api-input" style={{ position: 'relative', width: '35%', marginRight: 10 }}>

                      <Label for="woocommerceConsumerKey">
                        Consumer Key<div className="mandatory">*</div>
                      </Label>
                      <Input
                        name="woocommerceConsumerKey"
                        id="woocommerceConsumerKey"
                        type={show ? 'text' : 'password'}
                        placeholder=""
                        value={woocommerceConsumerKey}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeAPI}
                      >
                        {apiKey || null}
                      </Input>
                      {show ? (
                        <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                      ) : (
                        <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                      )}
                    </div>

                    <div className="company-api-input" style={{ position: 'relative', width: '35%', marginRight: 10 }}>
                      <Label for="woocommerceConsumerSecret">
                        Consumer Secret<div className="mandatory">*</div>
                      </Label>
                      <Input
                        name="woocommerceConsumerSecret"
                        id="woocommerceConsumerSecret"
                        type={show2 ? 'text' : 'password'}
                        placeholder=""
                        value={woocommerceConsumerSecret}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeAPI}
                      >
                        {apiKey || null}
                      </Input>
                      {show2 ? (
                        <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow2} />
                      ) : (
                        <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow2} />
                      )}
                    </div>

                    <div className="company-api-input" style={{ width: '35%' }}>
                      <Label for="shopUrl">
                        Shop URL<div className="mandatory">*</div>
                      </Label>
                      <Input
                        name="shopUrl"
                        id="shopUrl"
                        type="text"
                        placeholder=""
                        value={shopUrl}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeAPI}
                      >
                        {apiKey || null}
                      </Input>
                    </div>

                    <Button
                      style={{ top: -2 }}
                      disabled={showNoPermission}
                      className="company-api-button custom-modal-button"
                      color="primary"
                      onClick={this.changeWoocommerceAPI}
                      data-tip
                      data-for='confirmButton'
                      for="confirmButton"
                    >
                      Confirm
                      {(processing) ? (
                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                      ) : (null)}
                    </Button>

                    <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='confirmButton'>
                      <span>{apiTooltips.apiTabConfirm ? apiTooltips.apiTabConfirm.value : ''}</span>
                    </ReactTooltip>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="manual">
                {!!changeAPIError ? (<Alert color="danger"> {changeAPIError} </Alert>) : null}

                <Row style={{ marginTop: 5 }}>
                  <Col xs="9" style={{ display: 'inline-block', width: 500, minWidth: 500, maxWidth: '50%', color: '#7f8fa4' }}>
                      <ul>
                        <li>For non-website orders (e.g. in person, email, phone).</li>
                        <li>Customers quote their voucher codes.</li>
                        <li>Check and redeem codes in Payments.</li>
                      </ul>
                  </Col>
                  <Col xs="3" style={{ width: 150, maxWidth: '40%', display: 'inline-block', }}>
                      <Button
                        style={{ top: 33, position: 'relative' }}
                        disabled={showNoPermission}
                        className="company-api-button custom-modal-button"
                        color="primary"
                        onClick={this.changeManualAPI}
                      >
                        Confirm
                        {(processing) ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                    </Col>
                  {/* <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='confirmButton'>
                      <span>{apiTooltips.apiTabConfirm ? apiTooltips.apiTabConfirm.value : ''}</span>
                    </ReactTooltip> */}
                </Row>
              </TabPane>
            </TabContent>
            <Row>
              <Col>
                <Editor
                  className="content-management-text-area"
                  editorState={editorState}
                  toolbarHidden
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  readOnly
                />
              </Col>
            </Row>

            <Modal size="sm" isOpen={showConfirmModal} toggle={this.closeConfirmModal}>
              <ModalHeader toggle={this.closeConfirmModal}>
                <Button clasName="custom-modal-button" color="primary" onClick={this.approveConfirmModal}>
                  Confirm change
                </Button>
              </ModalHeader>
            </Modal>
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  console.log('state.company', state.company);
  return ({
    user: state.user.userData || {},
    companyName: state.company.name || null,
    linkedCompaniesAccount: state.company.company ? state.company.company.linkedCompaniesAccount : false,
    apiKey: state.company.apiKey || '',
    roles: state.users.companiesRoles || {},
    adminsRoles: state.admin.adminsRoles || {},
    apiTooltips: state.contentManagement.apiTooltips || {},
    activeAPI: state.company.activeAPI || 'shopify',
    adminViewAsCompany: state.admin.adminViewAsCompany || null,
    linkedCompaniesAPIs: state.company.linkedCompaniesAPIs || null,
  })
};

const mapDispatchToProps = {
  fetchCompanyApiKey: getCompanyApiKey,
  fetchAPIPageData: getAPIPageData,
  changeApiKey: changeCompanyApiKey,
  getShopifyAPI: getShopifyAPIData,
  changeShopifyAPI: changeShopifyAPIData,

  getWoocommerceAPI: getWoocommerceAPIData,
  changeWoocommerceAPI: changeWoocommerceAPIData,
  getManualAPI: getManualAPIData,
  changeManualAPI: changeManualAPIData,
  getActiveAPI: getActiveAPIData,
  changeActiveAPI: changeActiveAPIData,
  fetchAPITooltips: getAPITooltips,

  fetchLinkedAPIs: getLinkedAPIs,
  linkedAPIUpdate: updateLinkedAPI,
  linkedAPIAdd: addLinkedAPI,
  linkedAPIRemove: removeLinkedAPI,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Api));
