import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Button, Input, Spinner } from 'reactstrap';

import {
  useJsonToCsv
} from 'react-json-csv';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import AllPaymentsTable from './AllPaymentsTable';
import CompanyPaymentsTable from './CompanyPaymentsTable';
import CustomerPaymentsTable from './CustomerPaymentsTable';
import CampaignPaymentsTable from './CampaignPaymentsTable';

import {
  getPayments,
  sortPayments,
  refundStripePayment,
  cancelVoucher,
  getAdminPayments,
  getMoreAdminPayments,
  getSingleAdminPayment,
  resendGiftingEmail,
} from '../../../../actions/admin';

const TableHeight = 500;

class Payments extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    roles: PropTypes.shape(),
    sortAdminPayments: PropTypes.func.isRequired,
    fetchPayments: PropTypes.func.isRequired,
    getSinglePayment: PropTypes.func.isRequired,
    resendEmail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
    roles: {},
  };

  constructor(props) {
    super(props);

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);

    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);

    this.state = {
      error: null,
      loading: false,
      startDate: startDate,
      endDate: endDate,
      sortColumn: 'paymentDate',
      sortType: 'desc',
      //  startDate: new Date(),
      //  endDate: new Date(),
      timeFilter: '4weeks',
      typeFilter: 'all',
      showNoPermission: false,
      dontShowPage: false,
    };
  }

  componentDidMount() {
    const { fetchAdminPayments, user, isLoggedIn, history } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
        //  fetchPayments('all', '4weeks', null, null, 'paymentDate', 'desc');
        fetchAdminPayments('all', '4weeks', null, null, 'paymentDate', 'desc', null);

        if (user.role === 'admin') {
          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          const { adminsRoles } = this.props;

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.payments) {
            if (adminsRoles[user.role].permissions.payments === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });

            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }
      }
    } else {
      history.push('/');
    }
  }

  changeStartDate = (date) => {
    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  /*
    handleChangeFilter = (e, type) => {
      const { fetchPayments, sortAdminPayments } = this.props;
      const { typeFilter, timeFilter, startDate, endDate } = this.state;
  
      console.log('handleChangeFilter', type);
  
      if (type === 'typeFilter') {
        this.setState({ typeFilter: e.target.value });
        const { sortType, sortColumn } = this.state;
  
        sortAdminPayments(typeFilter, sortColumn, sortType);
  
        fetchPayments(e.target.value, timeFilter, startDate, endDate);
      } else if (type === 'timeFilter') {
        this.setState({ timeFilter: e.target.value });
  
        fetchPayments(typeFilter, e.target.value, startDate, endDate);
      }
    };  */

  /* sort = (sortColumn, sortType) => {
    const { sortAdminPayments } = this.props;
    const { typeFilter } = this.state;

    sortAdminPayments(typeFilter, sortColumn, sortType);

    this.setState({ sortType, sortColumn });
  } */

  sort = (sortColumn, sortType) => {
    const { fetchAdminPayments, sortAdminPayments } = this.props;
    const { startDate, endDate, typeFilter, filterValue } = this.state;

    this.setState({ sortType, sortColumn });

    if (typeFilter === 'all') {
      if (startDate && endDate) {
        fetchAdminPayments('all', 'custom', startDate, endDate, sortColumn, sortType, filterValue);
      }
    } else {
      sortAdminPayments(typeFilter, sortColumn, sortType);
    }
  }


  fetchPaymentsRange = async () => {
    const { fetchAdminPayments, fetchPayments } = this.props;
    const { startDate, endDate, typeFilter, filterValue } = this.state;

    if (startDate && endDate) {
      this.setState({ filterSpinner: true, sortColumn: 'paymentDate', sortType: 'desc' });

      if (typeFilter === 'all') {
        console.log('test 1');
        await fetchAdminPayments('all', 'custom', startDate, endDate, 'paymentDate', 'desc', filterValue).then((res) => {
          console.log('fetchPayments res 1', res);

          this.setState({ filterSpinner: false });
        });
        this.setState({ filterSpinner: false });
      } else {
        console.log('test 2');
        await fetchPayments(typeFilter, 'custom', startDate, endDate).then((res) => {
          console.log('fetchPayments res 2', res);

          this.setState({ filterSpinner: false });
        });
        this.setState({ filterSpinner: false });
      }
    }
  }

  fetchPayments = async () => {
    const { fetchAdminPayments, fetchPayments } = this.props;
    const { startDate, endDate, typeFilter, filterValue } = this.state;

    console.log('fetchPayments', typeFilter);

    if (startDate && endDate) {
      if (typeFilter === 'all') {
        await fetchAdminPayments('all', 'custom', startDate, endDate, 'paymentDate', 'desc', filterValue);
        this.setState({ refreshingPayments: null });
      } else {
        await fetchPayments(typeFilter, 'custom', startDate, endDate);
        this.setState({ refreshingPayments: null });
      }
    } else {
      this.setState({ refreshingPayments: null });
    }
  }

  refreshPayments = () => {
    const { user } = this.props;
    const { lastRefreshClick } = this.state;

    console.log('refreshPayments', lastRefreshClick);

    if (lastRefreshClick && (lastRefreshClick + (1000 * 60)) > Date.now()) {
      console.log('click less than 60s after last');

      this.setState({ lastRefreshTimeout: true });
      return null;
    }

    this.setState({ lastRefreshTimeout: null, refreshingPayments: true, lastRefreshClick: Date.now() });

    this.fetchPayments();
  }


  getMorePayments = () => {
    const { getMorePayments } = this.props;
    const { startDate, endDate, sortColumn, sortType, fetchingPayments, typeFilter, filterValue } = this.state;

    if (!fetchingPayments) {
      this.setState({ fetchingPayments: true });

      if (startDate && endDate) {
        if (typeFilter === 'all') {
          getMorePayments(typeFilter, 'custom', startDate, endDate, sortColumn, sortType, filterValue).then(() => {
            this.setState({ fetchingPayments: false });
          });
        }
      }
    }
  }

  /*  handleChangeFilter = event => {
      this.setState({ filterValue: event.target.value });
    } */

  handleChangeFilter = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    console.log('handleChangeFilter');

    this.setState({
      filterValue: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchPayments();
      }, 1200)
    });
  }

  onScroll = (x, y) => {
    const { payments } = this.props;
    let contextHeight = payments.length * 46;
    let top = Math.abs(y);
    if (contextHeight - top - TableHeight < 250) {
      this.getMorePayments();

      if (this.state.scrollTimeout) {
        clearTimeout(this.state.scrollTimeout);
      }

      this.setState({
        scrollTimeout: setTimeout(() => {
          this.getMorePayments();
        }, 700)
      });
    }
  };

  downloadData = (data) => {
    const { saveAsCsv } = useJsonToCsv();

    console.log('downloadData');

    const dataFiltered = [];

    const fields = {
      stripeId: 'Stripe ID',
      date: 'Date',
      status: "Status",
      customerName: 'Customer Name',
      customerEmail: 'Customer Email',
      company: 'Company',
      campaign: 'Campaign',
      gift: 'Gift',
      faceValue: 'Face Value',
      discount: 'Discount',
      step: 'Step',
      customerPaymentVoucher: 'Customer payment (�): Voucher',
      customerPaymentCardr: 'Customer payment (�): Card',
      customerPaymentTotal: 'Customer payment (�): Total',
      companyVoucher: 'Company (�): Voucher',
      podCard: 'POD (�): Card',
      stepprCommisionPerc: 'Steppr: Commission (%)',
      stepprCommisionVal: 'Steppr: Commission (�)',
      stepprCard: 'Steppr: Card (�)',
      stepprTotal: 'Steppr: Total (�)',
      refund: 'Refund',
      cancel: 'Cancel',
      refundCancel: 'Refund & Cancel'
    }

    data.map((row) => {
      let paymentDate = new Date(0);
      let refundDate = new Date(0);
      let canceledDate = new Date(0);
      let usedDate = new Date(0);
      let refundCancel = '';
      let status = null;

      if (row.paymentDate && row.paymentDate.seconds) {
        paymentDate.setUTCSeconds(row.paymentDate.seconds);
        paymentDate = paymentDate.toISOString().substring(0, 16).replace('T', ' ');
      } else {
        paymentDate = null;
      }

      console.log('row.paymentDate', row.paymentDate);

      if (row.refunded && row.refundDate && row.refundDate.seconds) {
        refundDate.setUTCSeconds(row.refundDate.seconds);
        refundDate = refundDate.toISOString().substring(0, 16).replace('T', ' ');
      } else {
        refundDate = null;
      }

      console.log('paymentDate', paymentDate);

      if (row.used && row.usedDate && row.usedDate.seconds) {
        usedDate.setUTCSeconds(row.usedDate.seconds);
        usedDate = usedDate.toISOString().substring(0, 16).replace('T', ' ');
      } else {
        usedDate = null;
      }

      if (row.canceled && row.canceledDate) {
        canceledDate.setUTCSeconds(row.canceledDate / 1000);
        canceledDate = canceledDate.toISOString().substring(0, 16).replace('T', ' ');
      } else {
        canceledDate = null;
      }

      if (row.refunded && row.canceled) {
        refundCancel = refundDate;
      } else if (row.used && row.usedDate) {
        refundCancel = 'Used';
      }

      if (row.canceled) {
        if (row.refunded) {
          status = `Cancelled (refunded) - ${canceledDate}`;
        } else {
          status = `Cancelled - ${canceledDate}`;
        }
      } else if (row.used) {
        if (row.refunded) {
          status = `Redeemed (refunded) - ${usedDate}`;
        } else {
          status = `Redeemed - ${usedDate}`;
        }
      } else {
        if (row.refunded) {
          if (row.canceled) {
            status = `Cancelled (refunded) - ${canceledDate}`;
          } else {
            status = `Unused(refunded)`;
          }
        } else {
          if (row.canceled) {
            status = `Cancelled - ${canceledDate}`;
          } else {
            status = 'Unused';
          }
        }
      }

      dataFiltered.push({
        stripeId: row.chargeId,
        date: paymentDate,
        status: status,
        customerName: row.customerName,
        customerEmail: row.customerEmail,
        company: row.companyName,
        campaign: row.voucherName,
        gift: row.gift ? 'Yes' : '-',
        faceValue: row.faceValue,
        discount: row.discount ? `${row.discount}%` : '',
        step: row.discountStep ? `Step ${row.discountStep}` : '',
        customerPaymentVoucher: row.amount_captured ? (row.amount_captured / 100).toFixed(2) : '',
        customerPaymentCardr: '0',
        customerPaymentTotal: row.amount_captured ? (row.amount_captured / 100).toFixed(2) : '',
        companyVoucher: row.amount_captured ? ((row.amount_captured - (row.commission || 0)) / 100).toFixed(2) : '',
        podCard: '0',
        stepprCommisionPerc: row.commissionPercent ? row.commissionPercent * 100 : 0,
        stepprCommisionVal: (row.commission / 100).toFixed(2),
        stepprCard: row.card_fee || 0,
        stepprTotal: (row.commission || row.card_fee) ? (((row.commission || 0) + (row.card_fee || 0)) / 100).toFixed(2) : '0',
        refund: refundDate || '-',
        cancel: canceledDate || '-',
        refundCancel: refundCancel || '-'
      });


      return null;
    })

    saveAsCsv({ data: dataFiltered, fields, filename: 'Payments' });
  }


  refundPayment = (chargeId, dbId, refundType) => new Promise((resolve) => {
    const { refundPayment, getSinglePayment } = this.props;

    console.log('refundType', chargeId, dbId, refundType);

    refundPayment(chargeId, dbId, refundType).then((res) => {
      console.log('refundPayment 222', res);
      getSinglePayment(dbId).then((res2) => {
        console.log('refundPayment 222 getSinglePayment', res2);
        return resolve();
      }).catch((err) => {
        console.log('refundPayment err', err);
        return resolve();
      });
    }).catch((err) => {
      console.log('refundPayment err', err);
      return resolve();
    });
  });

  cancelPayment = (chargeId, dbId) => new Promise((resolve) => {
    const { cancelPayment, getSinglePayment } = this.props;

    cancelPayment(chargeId, dbId).then((res) => {
      console.log('cancelPayment res', res);
      getSinglePayment(dbId).then((res2) => {
        console.log('cancelPayment getSinglePayment res', res2);
        return resolve();
      }).catch(() => {
        return resolve();
      });
    }).catch(() => {
      return resolve();
    });
  });

  resendEmail = (chargeId, dbId, newEmail) => new Promise((resolve) => {
    const { resendEmail } = this.props;

    console.log('resendEmail', chargeId, dbId);

    this.setState({ resendEmailProcessing: chargeId });

    resendEmail(chargeId, dbId, newEmail).then(() => {
      return resolve();
    }).catch(err => {
      return resolve();
    })
  });

  render() {
    const { payments } = this.props;
    const {
      typeFilter,
      startDate,
      endDate,
      sortType,
      sortColumn,
      showNoPermission,
      dontShowPage,
      filterValue,
      filterSpinner,
      refreshingPayments,
     } = this.state;

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view payments
            </CardBody>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Input
                  type="text"
                  value={filterValue}
                  name="filterValue"
                  onChange={this.handleChangeFilter}
                />
              </Col>
              <Col>
                <DateTimePicker
                  className="date-picker"
                  time={false}
                  culture="en"
                  format="MMM DD YYYY"
                  onChange={this.changeStartDate}
                  value={startDate}
                  defaultValue={new Date()}
                />
              </Col>
              <Col>
                <DateTimePicker
                  className="date-picker"
                  time={false}
                  culture="en"
                  format="MMM DD YYYY"
                  onChange={this.changeEndDate}
                  value={endDate}
                  defaultValue={new Date()}
                />
              </Col>
              <Col sm="6">
                <Button color="primary" onClick={this.fetchPaymentsRange}>
                  Filter
                  {filterSpinner ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
                <Button onClick={this.refreshPayments} color="primary" style={{ marginLeft: 5, paddingTop: 7, paddingBottom: 5 }}>
                  <i class="lni lni-refresh-circle-1-clockwise"></i>
                  {(refreshingPayments) ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>

                <a style={{ display: 'inline-block', marginLeft: 10, marginRight: 5, float: 'right' }} target="_blank" rel="noreferrer noopener" href='https://dashboard.stripe.com/dashboard'>
                  <Button disabled={showNoPermission} style={{ float: 'right' }} color="primary">
                    Stripe Dashboard
                  </Button>
                </a>
              </Col>
            </Row>
            <div class="table-responsive">

              {typeFilter === 'all' ? (
                <AllPaymentsTable
                  sortType={sortType}
                  sortColumn={sortColumn}
                  payments={payments}
                  sort={this.sort}
                  refundPayment={this.refundPayment}
                  cancelPayment={this.cancelPayment}
                  resendEmail={this.resendEmail}
                  showNoPermission={showNoPermission}
                  onScroll={this.onScroll}
                  downloadData={this.downloadData}
                />
              ) : (null)}

              {typeFilter === 'company' ? (
                <CompanyPaymentsTable
                  sortType={sortType}
                  sortColumn={sortColumn}
                  payments={payments}
                  sort={this.sort}
                  showNoPermission={showNoPermission}
                  downloadData={this.downloadData}
                />
              ) : (null)}

              {typeFilter === 'customer' ? (
                <CustomerPaymentsTable
                  sortType={sortType}
                  sortColumn={sortColumn}
                  payments={payments}
                  sort={this.sort}
                  showNoPermission={showNoPermission}
                  downloadData={this.downloadData}
                />
              ) : (null)}

              {typeFilter === 'campaign' ? (
                <CampaignPaymentsTable
                  sortType={sortType}
                  sortColumn={sortColumn}
                  payments={payments}
                  sort={this.sort}
                  showNoPermission={showNoPermission}
                  downloadData={this.downloadData}
                />
              ) : (null)}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  users: state.admin.companyOwners || {},
  payments: state.admin.adminPayments || [],
  paymentsTotals: state.admin.adminPaymentsTotals || null,
  adminsRoles: state.admin.adminsRoles || {},
});

const mapDispatchToProps = {
  fetchAdminPayments: getAdminPayments,
  getMorePayments: getMoreAdminPayments,
  fetchPayments: getPayments,
  sortAdminPayments: sortPayments,
  refundPayment: refundStripePayment,
  cancelPayment: cancelVoucher,
  getSinglePayment: getSingleAdminPayment,
  resendEmail: resendGiftingEmail,

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payments));
