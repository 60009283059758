import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Eye, EyeOff, Check, X } from 'react-feather';
import ScrollArea from 'react-scrollbar';

import reactStringReplace from 'react-string-replace';

import {
  useJsonToCsv
} from 'react-json-csv';

import ReactTooltip from 'react-tooltip';

import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Form, FormGroup, Alert, Media, Spinner } from 'reactstrap';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import Toggle from '../../components/Toggle/Toggle';
import AutoRefresh from '../../components/AutoRefresh/AutoRefresh';
import formattedNumber from '../../components/FormattedNumber/FormattedNumber';

import {
  getAllRoles,
  getCompanyMembers,
  getCompanyLogo,
  approvePendingUser,
  pauseActiveUser,
  createCompany,
  changeUserRole,
  deleteUser,
  getUnverifiedCount,
  getDataFiltered,
  getDataFilteredNextPage,
  sortData,
  openCompanyDashboard,
  changeCompanyDefaultCommission,
  changeTesterStatus,
  changeCompanyShowCommission,
  sendVerificationEmailAsAdmin,
  cancelUnverifiedCompany,
  getCompaniesComplianceData,
  setCompanyAccountToLinkedCompaniesAccount,
  getCompaniesActiveAPIData,
} from '../../../actions/admin';

import {
  updateCompanyWebsite,
} from '../../../actions/company';

import {
  getUserData,
} from '../../../actions/user';

class AllUsers extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    roles: PropTypes.shape(),
    approveUser: PropTypes.func.isRequired,
    pauseUser: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    getRoles: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    createComp: PropTypes.func.isRequired,
    changeRole: PropTypes.func.isRequired,
    deleteU: PropTypes.func.isRequired,
    fetchCompanyMembers: PropTypes.func.isRequired,
    accessCompanyDashboard: PropTypes.func.isRequired,
    changeCompanyCommission: PropTypes.func.isRequired,
    sendVerificationEmail: PropTypes.func.isRequired,
    cancelUnverified: PropTypes.func.isRequired,
    fetchCompaniesComplianceData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
    roles: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      roles: {
        teamOwner: 'Team Owner',
      },
      deleteUserModal: false,
      deleteUserErr: null,
      userToDelete: null,
      showPassword1: 'password',
      showPassword2: 'password',
      companyLogoModal: false,
      companyLogo: null,
      websites: {},
      showNoPermission: false,
      dontShowPage: false,
      companyAccessPermission: false,
      includeUnverified: false,
      includeTesters: false,
      shopifyLink: "https://shopify.dev/docs/api/usage/response-codes",
      stripeLink: "https://stripe.com/docs/error-codes",
    };
  }

  componentDidMount() {
    const { fetchCompaniesFiltered, fetchUnverifiedCount, user, isLoggedIn, history, fetchCompaniesComplianceData, fetchCompaniesActiveAPIData } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {

        fetchCompaniesComplianceData();
        fetchCompaniesActiveAPIData();
        fetchCompaniesFiltered('companies', 'lastActive', 'descending', null, null).then(() => {
          this.setState({ sortType: 'desc', sortColumn: 'lastActiveSort', currentOrdering: 'lastActive', isAscending: true });
        });

       // fetchCompaniesFiltered('companies', orderId, ordering, filterValue, includeUnverified).then(() => {
      //    this.setState({ sortType, sortColumn, currentOrdering: orderId, isAscending });
       // });

      //  fetchCompaniesFiltered('companies', null, null, null, false);

        fetchUnverifiedCount().then(res => {
          this.setState({ unverifiedCount: res });
        });

        if (user.role === 'admin') {
          this.setState({ showNoPermission: false, companyAccessPermission: false, dontShowPage :false });
        } else {
          const { adminsRoles } = this.props;

          console.log('adminsRoles[user.role]', adminsRoles, user.role);

          console.log('adminsRoles[user.role] 2', adminsRoles[user.role]);

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.companies) {
            if (adminsRoles[user.role].permissions.companies === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.companyAccess) {
            this.setState({ companyAccessPermission: false });
          } else {
            this.setState({ companyAccessPermission: true });
          }
        }
      }
    } else {
      history.push('/');
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  openCreateCompany = () => {
    this.setState({ createCompanyModal: true, roleInCompany: 'owner', createCompanyError: null });
  }

  closeCreateComapny = () => {
    this.setState({ createCompanyModal: false });
  }

  createCompany = () => {
    const { createComp } = this.props;
    const {
      email,
      password,
      confirmPassword,
      name,
      roleInCompany,
      companyName,
      companyNumber,
      phoneNumber,
    } = this.state;

    if (!name) {
      this.setState({ createCompanyError: 'Missing name' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!roleInCompany) {
      this.setState({ createCompanyError: 'Missing role in company' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!companyName) {
      this.setState({ createCompanyError: 'Missing company name' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!companyNumber) {
      this.setState({ createCompanyError: 'Missing company number' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!email) {
      this.setState({ createCompanyError: 'Missing email' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!confirmPassword || !password) {
      this.setState({ createCompanyError: 'Missing password' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (confirmPassword !== password) {
      this.setState({ createCompanyError: 'Passwords dont match' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else {
      if (password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/)) {
        this.setState({ createAdminErr: null });

        createComp(email, password, name, roleInCompany, companyName, companyNumber, phoneNumber).then(res => {
          this.setState({ createCompanyModal: false, email: null, password: null, name: null, roleInCompany: null, companyName: null, companyNumber: null, phoneNumber: null, createCompanyError: null });
        }).catch(err => {
          console.log(err);

          this.setState({ createCompanyError: err });

          if (this.modalScrollbar) {
            this.modalScrollbar.scrollTop();
          }
        })
      } else {
        this.setState({ createCompanyError: 'Password needs to be at least 8 characters, have at least 1 uppercase, lowercase, number, symbol.' });

        if (this.modalScrollbar) {
          this.modalScrollbar.scrollTop();
        }
      }
    }
  }

  selectRoleinCompany = (e) => {
    this.setState({ roleInCompany: e.target.value });
  }

  openChangeRole = (e, uid, role) => {
    e.stopPropagation();
    this.setState({ changeRoleModal: true, changeRoleUid: uid, role });
  }

  closeChangeRole = () => {
    this.setState({ changeRoleModal: false, changeRoleUid: null, role: null });
  }

  selectRole = (e) => {
    this.setState({ role: e.target.value });
  }

  changeRole = () => {
    const { changeRole } = this.props;
    const { changeRoleUid, role } = this.state;

    changeRole(changeRoleUid, role).then(res => {
      this.setState({ changeRoleModal: false, changeRoleUid: null, role: null });
    }).catch(err => {
      console.log(err);
    });
  }

  openInitiateDeleteUser = (e, uid, role) => {
    e.stopPropagation();
    this.setState({ initiateDeleteUserModal: true, userToDelete: uid, userToDeleteRole: role, deleteUserErr: null });
  }

  closeInitiateDeleteUser = () => {
    this.setState({ initiateDeleteUserModal: false, userToDelete: null, userToDeleteRole: null, deleteUserErr: null });
  }

  confirmInitiateDeleteUser = () => {
    this.setState({ initiateDeleteUserModal: false, deleteUserModal: true, deleteUserErr: null });
  }

  toggleDeleteUser = (e, uid, role) => {
    e.stopPropagation();

    const { deleteUserModal } = this.state;
    if (deleteUserModal) {
      this.setState({ deleteUserModal: false, userToDelete: null, userToDeleteRole: null, deleteUserErr: null });
    } else {
      this.setState({ deleteUserModal: true, userToDelete: uid, userToDeleteRole: role, deleteUserErr: null });
    }
  }

  closeDeleteUser = () => {
    this.setState({ deleteUserModal: false, userToDelete: null, userToDeleteRole: null, deleteUserErr: null });
  }

  deleteUser = () => {
    const { deleteU } = this.props;
    const { userToDelete, userToDeleteRole } = this.state;

    let type = 'user';
    if (userToDeleteRole === 'teamOwner') {
      type = 'teamOwner';
    }

    this.setState({ deletingUser: true });
    deleteU(userToDelete, type).then(res => {
      this.refresh();

      this.setState({ deletingUser: false, deleteUserModal: false, userToDelete: null, userToDeleteRole: null, deleteUserErr: null });
    }).catch(err => {
      console.log(err);
      this.setState({ deletingUser: false, deleteUserErr: err });
    });
  }

  fetchCompanyUsers = (company) => {
    const { fetchCompanyMembers } = this.props;
    const { openCompany } = this.state;

    if (company) {
      if (openCompany === company) {
        this.setState({ openCompany: null });
      } else {
        fetchCompanyMembers(company).then(() => {
          this.setState({ openCompany: company });
        });
      }
    }
  }

  fetchCompanyLogo = (e, companyId) => {
    e.stopPropagation();
    const { fetchCompanyLogo } = this.props;
    const { openCompanyLogo } = this.state;

    if (companyId) {
      if (openCompanyLogo === companyId) {
        this.setState({ companyLogoModal: false, openCompanyLogo: null });
      } else {
        fetchCompanyLogo(companyId).then((res) => {
          if (res) {
            this.setState({ companyLogoModal: true, openCompanyLogo: companyId, companyLogo: res });
          } else {
            this.setState({ companyLogoModal: true, openCompanyLogo: companyId, companyLogo: null });
          }
        });
      }
    }
  }

  closeCompanyLogo = () => {
    this.setState({ companyLogoModal: false, openCompanyLogo: null, companyLogo: null });
  }

  selectFilterType = (e) => {
    const type = e.target.value;

    if (type === '-') {
      this.setState({ filterType: null, filterValue: null });
    } else {
      this.setState({ filterType: type, filterValue: null });
    }
  }

  handleChangeFilter = (e) => {
    if (this.state.typingTimeout) {
       clearTimeout(this.state.typingTimeout);
    }

    this.setState({
       [e.target.name]: e.target.value,
       typingTimeout: setTimeout(() => {
         this.filter();
       }, 900)
    });
  }

  filter = () => {
    const { fetchCompaniesFiltered } = this.props;
    const { filterValue, includeUnverified } = this.state;

    fetchCompaniesFiltered('companies', null, null, filterValue, includeUnverified);
    this.setState({ currentOrdering: null });
  }

  toggleShowPassword = (id) => {
    if (id === 1) {
      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  handleWebsiteChange = (e) => {
    this.setState({
      websiteToEditValue: e.target.value,
    });
  }

  startChangeWebsite = (e, key, value) => {
    e.stopPropagation();
    this.setState({
      websiteToEdit: key,
      websiteToEditValue: value,
    });
  }

  saveChangeWebsite = (e) => {
    e.stopPropagation();
    const { updateWebsite } = this.props;
    const { websiteToEdit, websiteToEditValue } = this.state;

    if (websiteToEdit && websiteToEditValue) {
      updateWebsite(websiteToEdit, websiteToEditValue);

      this.setState({
        websiteToEdit: null,
        websiteToEditValue: null,
      });
    }
  }

  closeChangeWebsite = (e) => {
    e.stopPropagation();
    this.setState({
      websiteToEdit: null,
      websiteToEditValue: null,
    });
  }

  fetchCompaniesFiltered = (orderId) => {
    const { fetchCompaniesFiltered } = this.props;
    const { currentOrdering, isAscending, filterValue, includeUnverified } = this.state;

    console.log('fetchCompaniesFiltered', orderId);

    if (currentOrdering !== orderId) {
      fetchCompaniesFiltered('companies', orderId, 'ascending', filterValue, includeUnverified);
      this.setState({ currentOrdering: orderId, isAscending: true });
    } else {
      if (isAscending) {
        fetchCompaniesFiltered('companies', orderId, 'descending', filterValue, includeUnverified);
        this.setState({ currentOrdering: orderId, isAscending: false });
      } else {
        fetchCompaniesFiltered('companies', orderId, 'ascending', filterValue, includeUnverified);
        this.setState({ currentOrdering: orderId, isAscending: true });
      }
    }
  }

  fetchCompaniesFilteredNextPage = (limit) => {
    const { fetchCompaniesFilteredNextPage } = this.props;
    const { currentOrdering, isAscending, filterValue, includeUnverified } = this.state;

    /*
    if (limit === 10) {
      this.setState({ loadMoreSpinner: 1 });
    } else if (limit === 100) {
      this.setState({ loadMoreSpinner: 2 });
    } else if (limit === 'all') {
      this.setState({ loadMoreSpinner: 3 });
    } */

    if (isAscending) {
      fetchCompaniesFilteredNextPage('companies', currentOrdering, 'ascending', filterValue, limit, includeUnverified).then(() => {
        this.setState({ loadMoreSpinner: null });
      });
    } else {
      fetchCompaniesFilteredNextPage('companies', currentOrdering, 'descending', filterValue, limit, includeUnverified).then(() => {
        this.setState({ loadMoreSpinner: null });
      });
    }
  }


  handleExpanded = (rowData, dataKey) => {
    const { fetchCompanyMembers } = this.props;
    const { expandedRow } = this.state;

    console.log('handleExpanded', rowData, dataKey);

    if (dataKey) {
      if (dataKey === expandedRow) {
        this.setState({
            expandedRow: null,
          });
      } else {
        if (rowData.company) {
          fetchCompanyMembers(rowData.company).then(() => {
            this.setState({
                expandedRow: [dataKey],
              });
          });
        }
      }
    }
  }

  sort = (sortColumn, sortType) => {
    const { fetchCompaniesFiltered } = this.props;
    const { filterValue, includeUnverified } = this.state;

    let ordering = 'ascending';
    let isAscending = true;
    if (sortType === 'desc') {
      ordering = 'descending';
      isAscending = false;
    }

    let orderId;
    if (sortColumn === 'dateCreated') {
      orderId = 'dateCreated';
    } else if (sortColumn === 'companyName') {
      orderId = 'companyDashboardNameLower';
    } else if (sortColumn === 'firstName') {
      orderId = 'firstNameLower';
    } else if (sortColumn === 'name') {
      orderId = 'nameLower';
    } else if (sortColumn === 'email') {
      orderId = 'emailLower';
    } else if (sortColumn === 'lastActiveSort') {
      orderId = 'lastActive';
    } else {
      orderId = sortColumn
    }

    console.log('refresh', orderId, sortType, isAscending);

    fetchCompaniesFiltered('companies', orderId, ordering, filterValue, includeUnverified).then(() => {
      this.setState({ sortType, sortColumn, currentOrdering: orderId, isAscending });
    });
  }

  openApproveModal = (e, uid, role, index, status) => {
    e.stopPropagation();
    this.setState({ approveUserModal: true, approveUserUid: uid, approveUserValue: status, approveUserIndex: index, approveUserRole: role  });
  }

  closeApproveModal = () => {
    this.setState({ approveUserModal: false, approveUserUid: null, approveUserValue: null, approveUserIndex: null, approveUserRole: null  });
  }

  changeUserApproval = (e) => {
    const { approveUser, pauseUser, user } = this.props;
    const { approveUserValue, approveUserIndex, approveUserRole, approveUserUid } = this.state;

    if (approveUserUid && approveUserValue && (approveUserIndex || approveUserIndex === 0)) {
      const value = e.target.value;

      if (value === approveUserValue) {
        return null;
      }

      let type = 'user';
      if (approveUserRole === 'teamOwner') {
        type = 'teamOwner'
      }

      let adminName;

      if (user.name || (user.firstName && user.lastName)) {
        adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
      }

      if (value === 'approved') {
        approveUser(approveUserUid, type, approveUserIndex, adminName).then(res => {
          this.setState({ approveUserModal: false, approveUserUid: null, approveUserValue: null, approveUserIndex: null, approveUserRole: null  });
        }).catch(err => {
          console.log(err);
        });
      } else if (value === 'pending') {
        pauseUser(approveUserUid, type, approveUserIndex, adminName).then(res => {
          this.setState({ approveUserModal: false, approveUserUid: null, approveUserValue: null, approveUserIndex: null, approveUserRole: null  });
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  changeUserApproval2 = (e, approveUserValue, approveUserRole, approveUserUid, approveUserIndex) => {
    e.stopPropagation();
    console.log('changeUserApproval2', approveUserValue, approveUserRole, approveUserUid, approveUserIndex);
    const { approveUser, pauseUser, user } = this.props;

    if (approveUserUid && approveUserValue && (approveUserIndex || approveUserIndex === 0)) {
      let type = 'user';
      if (approveUserRole === 'teamOwner') {
        type = 'teamOwner'
      }

      console.log('approveUserValue', approveUserValue, type);

      let adminName;

      if (user.name || (user.firstName && user.lastName)) {
        adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
      }

      if (approveUserValue === 'pending') {
        approveUser(approveUserUid, type, approveUserIndex, adminName).then(res => {
          this.setState({ approveUserModal: false, approveUserUid: null, approveUserValue: null, approveUserIndex: null, approveUserRole: null  });
        }).catch(err => {
          console.log(err);
        });
      } else if (approveUserValue === 'approved') {
        pauseUser(approveUserUid, type, approveUserIndex, adminName).then(res => {
          this.setState({ approveUserModal: false, approveUserUid: null, approveUserValue: null, approveUserIndex: null, approveUserRole: null  });
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  changeTesterStatus = (e, isTester, uid, index) => {
    e.stopPropagation();
    console.log('changeTesterStatus', isTester, uid, index);
    const { changeUserTesterStatus, user } = this.props;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    if (uid) {
      changeUserTesterStatus(uid, isTester || null, index, adminName).then(res => {
      //  this.setState({ approveUserModal: false, approveUserUid: null, approveUserValue: null, approveUserIndex: null, approveUserRole: null  });
      }).catch(err => {
        console.log(err);
      });
    }
  }

  changeShowCommission = (e, showCommission, uid, index) => {
    if (e) {
      e.stopPropagation();
    }
    console.log('changeShowCommission', showCommission, uid, index);
    const { changeShowCommission, user } = this.props;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    if (uid) {
      changeShowCommission(uid, showCommission || null, index, adminName).then(res => {
      }).catch(err => {
        console.log(err);
      });
    }
  }

  accessCompanyDashboard = (e, companyId, companyName) => {
    e.preventDefault();
    const { accessCompanyDashboard, history, match } = this.props;

    const { path } = match;

    if (companyId && companyName) {
      accessCompanyDashboard(companyId, companyName, path).then((res) => {
        if (res && res.status && res.status === 'success') {
          history.push('/dashboard');
        }
      })
    }
  }

  openChangeCommission = (id, commission, index) => {
    if (commission) {
      this.setState({ commissionEntered: commission, commissionCompanyId: id, commisionIndex: index });
    } else {
      this.setState({ commissionEntered: 0, commissionCompanyId: id, commisionIndex: index });
    }
  }

  closeChangeCommission = () => {
    this.setState({ commissionEntered: null, commissionCompanyId: null, commisionIndex: null });
  }

  changeCompanyCommission = () => {
    const { changeCompanyCommission, user } = this.props;
    const { commissionEntered, commissionCompanyId, commisionIndex } = this.state;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    changeCompanyCommission(commissionCompanyId, commissionEntered, commisionIndex, adminName).then((res) => {
      this.changeShowCommission(null, false, commissionCompanyId, commisionIndex);

      this.setState({ commissionEntered: null, commissionCompanyId: null, commisionIndex: null });
    });
  }

  handleChangeCommission = (e) => {
    if (e.target && e.target.value) {
      let newVal = parseInt(e.target.value, 10);

      if (newVal > 100) {
        newVal = 100;
      } else if (newVal < 0) {
        newVal = 0;
      }

      this.setState({
        commissionEntered: newVal,
      });
    }
  };

  downloadData = async (data) => {
    const { saveAsCsv } = useJsonToCsv();

    const dataFiltered = [];

    this.setState({ downloadSpinner: true });

      const fields = {
        api: 'API',
        date: 'Date',
        companyNameDisplayed: 'Company Name (displayed)',
        companyName: 'Company Name',
        lastActive: "Last Active",
        permissionLevel: 'Permission Level',
        accountAccess: 'Account Access',
        name: 'Name',
        roleInCompany: 'Role in company',
        email: 'Email',
        phoneNumber: 'Phone Number',
        website: 'Website',
        commission: 'Commision %',
        totalVouchersCount: 'Total Campaigns',
        liveVouchersCount: 'Live Campaigns',
        customers: 'Customers',
        usersCount: 'Users Count',
        lastCampaignStartDate: 'Last Campaign Start Date',
        lastCampaignEndDate: 'Last Campaign End Date',
        companyNumber: 'Company Number',
        rating: 'Rating',
        ratingCount: 'Rating #',
      }

      data.map((row) => {
        let dateCreated = new Date(0);
        let lastActive = new Date(0);
        let startDate = new Date(0);
        let endDate = new Date(0);

        if (row.dateCreated) {
          dateCreated.setUTCSeconds(row.dateCreated / 1000);
          dateCreated = dateCreated.toISOString().substring(0, 16).replace('T', ' ');
        } else {
          dateCreated = null;
        }

        if (row.lastActive) {
          lastActive.setUTCSeconds(row.lastActive / 1000);
          lastActive = lastActive.toISOString().substring(0, 16).replace('T', ' ');
        } else {
          lastActive = null;
        }

        if (row.startDate && row.startDate.seconds) {
          startDate.setUTCSeconds(row.startDate.seconds);
          startDate = startDate.toISOString().substring(0, 16).replace('T', ' ');
        } else {
          startDate = null;
        }

        if (row.endDate && row.endDate.seconds) {
          endDate.setUTCSeconds(row.endDate.seconds);
          endDate = endDate.toISOString().substring(0, 16).replace('T', ' ');
        } else {
          endDate = null;
        }

        dataFiltered.push({
          api: true,
          date: dateCreated,
          companyNameDisplayed: row.companyName,
          companyName: row.companyDashboardName,
          lastActive: lastActive,
          permissionLevel: 'Admin',
          accountAccess: row.status !== 'pending',
          name: row.name,
          roleInCompany: row.roleInCompany,
          email: row.email,
          phoneNumber: row.phoneNumber,
          website: row.website,
          commission: row.commission || 0,
          totalVouchersCount: row.totalVouchersCount || 0,
          liveVouchersCount: row.liveVouchersCount || 0,
          customers: row.customers || 0,
          usersCount: row.userCount ? (row.userCount + 1) : 1,
          lastCampaignStartDate: startDate,
          lastCampaignEndDate: endDate,
          companyNumber: row.companyNumber,
          rating: (row.ratingsCount && row.ratingsTotalVal) ? (Math.round((row.ratingsTotalVal / row.ratingsCount) * 10) / 10):('0'),
          ratingCount: row.ratingsCount || 0,
        });

        return null;
      })

    await saveAsCsv({ data: dataFiltered, fields, filename: 'Companies' });

    this.setState({ downloadSpinner: false });
  }


  refresh = () => {
    const { fetchCompaniesFiltered } = this.props;
    const { currentOrdering, isAscending, filterValue, includeUnverified, sortType } = this.state;

    console.log('refresh', currentOrdering, sortType, isAscending);

      if (isAscending) {
        fetchCompaniesFiltered('companies', currentOrdering, 'descending', filterValue, includeUnverified);
      } else {
        fetchCompaniesFiltered('companies', currentOrdering, 'ascending', filterValue, includeUnverified);
      }
  }

  resendVerificationEmail = (uid) => {
    const { sendVerificationEmail } = this.props;

    this.setState({ sendVerificationEmailProcessing: uid });

    sendVerificationEmail(uid).then((res) => {
      console.log(res);
      this.setState({ sendVerificationEmailProcessing: false });
    }).catch(err => {
      console.log(err);
      this.setState({ sendVerificationEmailProcessing: false });
    });
  }

  cancelUnverified = (uid) => {
    const { cancelUnverified, fetchUnverifiedCount } = this.props;

    this.setState({ cancelUnverifiedProcessing: uid });

    cancelUnverified(uid).then((res) => {
      console.log(res);

      this.refresh();

      fetchUnverifiedCount().then(res => {
        this.setState({ unverifiedCount: res, cancelUnverifiedProcessing: null });
      });
    }).catch(err => {
      this.setState({ cancelUnverifiedProcessing: null });
      console.log(err);
    });
  }

  toggleIncludeUnverified = async () => {
    const { fetchUnverifiedCount } = this.props;
    const { includeUnverified } = this.state;

    console.log('toggleIncludeUnverified', includeUnverified);

    await this.setState({ includeUnverified: !includeUnverified });

    this.refresh();

    fetchUnverifiedCount().then(res => {
      this.setState({ unverifiedCount: res });
    });
  }

  toggleIncludeTesters = () => {
    const { fetchUnverifiedCount } = this.props;
    const { includeTesters } = this.state;

    this.setState({ includeTesters: !includeTesters });

    //  this.refresh();

    //  fetchUnverifiedCount().then(res => {
    //    this.setState({ unverifiedCount: res });
    //  });
  }

  openModalSetCompanyAccountToLinked = (e, id, value) => {
    let newVal = false;

    if (!value) {
      newVal = true;
    }

    this.setState({ setCompanyAccountToLinkedModal: true, setCompanyAccountToLinkedId: id, setCompanyAccountToLinkedNewVal: newVal });
  }

  closeModalSetCompanyAccountToLinked = () => {
    this.setState({ setCompanyAccountToLinkedModal: null, setCompanyAccountToLinkedId: null, setCompanyAccountToLinkedNewVal: null });
  }

  setCompanyAccountToLinked = () => {
    const { setCompanyAccountToLinked } = this.props;
    const { setCompanyAccountToLinkedId, setCompanyAccountToLinkedNewVal } = this.state;

    this.setState({ setCompanyAccountToLinkedSpinner: true });

    setCompanyAccountToLinked(setCompanyAccountToLinkedId, true).then((res) => {
      console.log(res);
      this.setState({ setCompanyAccountToLinkedSpinner: null, setCompanyAccountToLinkedModal: null, setCompanyAccountToLinkedId: null, setCompanyAccountToLinkedNewVal: null });

      this.refresh();
    });
  }

  render() {
    const { users, companyUsers, user, roles, companiesComplianceData, companiesApiActiveType } = this.props;
    const {
      changeRoleModal,
      createCompanyModal,
      email,
      password,
      confirmPassword,
      showPassword1,
      showPassword2,
      name,
      roleInCompany,
      companyName,
      companyNumber,
      phoneNumber,
      role,
      filterValue,
      deleteUserModal,
      initiateDeleteUserModal,
      deleteUserErr,
      createCompanyError,
      companyLogo,
      companyLogoModal,
      sortType,
      sortColumn,
      expandedRow,
      approveUserModal,
      approveUserValue,
      commissionEntered,
      commissionCompanyId,
      showNoPermission,
      dontShowPage,
      companyAccessPermission,
      includeUnverified,
      includeTesters,
      unverifiedCount,
      shopifyLink,
      stripeLink,
      sendVerificationEmailProcessing,
      cancelUnverifiedProcessing,
      downloadSpinner,
      userToDelete,
      deletingUser,
      loadMoreSpinner,
      setCompanyAccountToLinkedSpinner,
      setCompanyAccountToLinkedModal,
     } = this.state;

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view all companies
            </CardBody>
          </Card>
        </div>
      );
    }

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view all companies
            </CardBody>
          </Card>
        </div>
      );
    }

    let usersFiltered = users;

    console.log('usersusersusers', users);

    if (!includeTesters) {
      usersFiltered = users.filter((user) => !user.isTester);
    } else {
      usersFiltered = users.filter((user) => user.isTester);
    }

    console.log('usersFiltered', usersFiltered);

    return (
      <div>
        <Card>
          <CardBody>
            <Row className="body-filters">
              <Col xs="5">
                <Input
                  type="text"
                  name="filterValue"
                  id="filterValue"
                  value={filterValue}
                  onChange={this.handleChangeFilter}
                />
              </Col>
              <Col xs="1">
                <AutoRefresh refreshFunction={this.refresh} refreshTime={60} />
              </Col>
              <Col xs="6">
                <div style={{ float: 'right' }}>
                  <span style={{ paddingRight: 7 }}>
                    {`Show unverified (${(unverifiedCount || 0)})`}
                  </span>
                  <Toggle disabled={showNoPermission} checked={includeUnverified} onChange={this.toggleIncludeUnverified} />
                </div>

                <div style={{ float: 'right', paddingRight: 15 }}>
                  <span style={{ paddingRight: 7 }}>
                    {`Show testers`}
                  </span>
                  <Toggle disabled={showNoPermission} checked={includeTesters} onChange={this.toggleIncludeTesters} />
                </div>
              </Col>
            </Row>
            <div class="table-responsive">
              <Table
                style={{ fontSize: 14 }}
                sortType={sortType}
                sortColumn={sortColumn}
                data={usersFiltered}
                virtualized
                minHeight={590}
                height={590}
                onSortColumn={this.sort}
                shouldUpdateScroll={false}
                rowKey='uid'
                rowHeight={46}
                headerHeight={64}
                expandedRowKeys={expandedRow}
                rowClassName={(rowData) => {
                  return (`${`${(rowData && rowData.newUser) ? ' admin-table-new-user' : ''} ${(rowData && !rowData.hasVerifiedEmail) ? ' admin-table-unverified-user' : ''}`}`)
                }}
              >

                <Column width={210} sortable resizable fixed>
                  <HeaderCell>Trading name</HeaderCell>
                  <Cell dataKey="companyName">
                    {(rowData, rowIndex) => {
                      return (
                        <Button disabled={companyAccessPermission || !rowData.hasVerifiedEmail} color="link" onClick={(e) => this.accessCompanyDashboard(e, rowData.company, rowData.companyName)}>
                          <div> {rowData.companyName} </div>
                        </Button>
                      )
                    }}
                  </Cell>
                </Column>
                <ColumnGroup className="text-center" header="Setup Indicators">
                  <Column width={130} resizable>
                    <HeaderCell>Basic Information</HeaderCell>
                    <Cell dataKey="validShopify">
                      {(rowData, rowIndex) => {
                        let basicInformationSetup = false;

                        if (rowData && Object.keys(rowData) && Object.keys(rowData).length) {
                          console.log('rowData', rowData);
                          console.log('companiesComplianceData', companiesComplianceData);

                          const { company, companyName, companyType, companyNumber, website, primaryVoucherImage, logo, category1, description } = rowData;

                          let complianceDataQ1 = false;
                          let complianceDataQ2 = false;
                          let complianceDataQ3 = false;
                          let complianceDataQ4 = false;
                          let complianceDataQ5 = false;

                          if (companiesComplianceData && companiesComplianceData[company]) {
                            if (companiesComplianceData[company].question1 || companiesComplianceData[company].question1 === false) complianceDataQ1 = true;
                            if (companiesComplianceData[company].question2 || companiesComplianceData[company].question2 === false) complianceDataQ2 = true;
                            if (companiesComplianceData[company].question3 || companiesComplianceData[company].question3 === false) complianceDataQ3 = true;
                            if (companiesComplianceData[company].question4 || companiesComplianceData[company].question4 === false) complianceDataQ4 = true;
                            if (companiesComplianceData[company].question5 || companiesComplianceData[company].question5 === false) complianceDataQ5 = true;
                          }

                          console.log('complianceDataQ1', complianceDataQ1);

                          if ((companyName && (companyType && (companyNumber || companyType === 'individual')) && website && category1 && description // && primaryVoucherImage && logo
                            && complianceDataQ1 && complianceDataQ2 && complianceDataQ3 && complianceDataQ4 && complianceDataQ5)) {
                            basicInformationSetup = true;
                          }
                        }

                        return (
                          <div className={`${(basicInformationSetup) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                        )
                      }}
                    </Cell>
                  </Column>

                  <Column width={180} resizable sortable>
                    <HeaderCell>Website</HeaderCell>
                    <Cell dataKey="validShopify">
                      {(rowData, rowIndex) => {
                        return (
                          <>
                            {(rowData.linkedCompaniesAccount) ? (
                              <>
                                {(rowData.allValidApis && rowData.someValidApis) && (
                                  <>
                                    <div style={{ display: 'inline-block' }} className='status-indicators-active' />
                                    <div style={{ display: 'inline-block', marginLeft: 5, paddingBottom: 2 }}>
                                      {'Multi'}
                                    </div>
                                  </>
                                )}
                                {(!rowData.allValidApis && rowData.someValidApis) && (
                                  <>
                                    <div style={{ display: 'inline-block' }} className='status-indicators-yellow' />
                                    <div style={{ display: 'inline-block', marginLeft: 5, paddingBottom: 2 }}>
                                      {'Multi'}
                                    </div>
                                  </>
                                )}
                                {((!rowData.allValidApis && !rowData.someValidApis) || (rowData.allValidApis && !rowData.someValidApis)) && (
                                  <>
                                    <div style={{ display: 'inline-block' }} className='status-indicators-inactive' />
                                    {(!rowData.allValidApis && !rowData.someValidApis) ? (
                                      <div style={{ display: 'inline-block', marginLeft: 5, paddingBottom: 2 }}>
                                        {'Multi'}
                                      </div>
                                    ) : (null)}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {(rowData.validShopify || rowData.validWoocommerce || rowData.validManual) ? (
                                  <>
                                    <div style={{ display: 'inline-block' }} className='status-indicators-active' />
                                    <div style={{ display: 'inline-block', marginLeft: 5, paddingBottom: 2 }}>
                                        {/* {rowData.validWoocommerce ? 'Woocommerce' : 'Shopify'} */}
                                        {(companiesApiActiveType && companiesApiActiveType[rowData.uid]) ? (companiesApiActiveType[rowData.uid].type !== 'manual' ? (companiesApiActiveType[rowData.uid].type === 'woocommerce' ? 'Woocommerce' : 'Shopify') : 'Manual') : ''}
                                    </div>
                                  </>
                                ): (
                                  <>
                                    <div data-tip data-for={`company-shopify-error-${rowData.uid}`} style={{ display: 'inline-block' }} className='status-indicators-inactive' />
                                    <div style={{ display: 'inline-block', marginLeft: 5, paddingBottom: 2 }}>
                                          {(companiesApiActiveType && companiesApiActiveType[rowData.uid]) ? (companiesApiActiveType[rowData.uid].type !== 'manual' ? (companiesApiActiveType[rowData.uid].type === 'woocommerce' ? 'Woocommerce' : 'Shopify') : 'Manual') : ''}
                                    </div>
                                        
                                    <ReactTooltip delayHide={3000} clickable place="right" className='react-tooltip-company-error' effect="solid" id={`company-shopify-error-${rowData.uid}`}>
                                      <span>
                                        {
                                          rowData.shopifyError ? (
                                            reactStringReplace(rowData.shopifyError, '{{shopifyLink}}', (match, i) => (
                                              <a href={shopifyLink} target="_blank">More</a>
                                            )))
                                        :
                                          'Unspecified error'
                                        }
                                      </span>
                                    </ReactTooltip>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )
                      }}
                    </Cell>
                  </Column>

                  <Column width={160} resizable sortable>
                    <HeaderCell>Payments</HeaderCell>
                    <Cell dataKey="validStripe">
                      {(rowData, rowIndex) => {
                        return (
                          <>
                            {rowData.validStripe ? (
                              <div className='status-indicators-active' />
                            ) : (
                              <>
                                <div data-tip data-for={`company-stripe-error-${rowData.uid}`} className='status-indicators-inactive' />
                                  <ReactTooltip place="right" className='react-tooltip-company-error' effect="solid" id={`company-stripe-error-${rowData.uid}`}>
                                    <span>
                                      {
                                        rowData.stripeError ? (
                                          reactStringReplace(rowData.stripeError, '{{stripeLink}}', (match, i) => (
                                            <a href={stripeLink} target="_blank">More</a>
                                          )))
                                          :
                                          'Unspecified error'
                                      }
                                    </span>
                                </ReactTooltip>
                              </>
                            )}
                          </>
                        )
                      }}
                    </Cell>
                  </Column>
                </ColumnGroup>

                {/*  {(rowData, rowIndex) => {
                      return (
                        <div className={`${rowData.validStripe ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                      )
                    }} */}

                <Column width={140} sortable resizable>
                  <HeaderCell>Joined</HeaderCell>
                  <Cell dataKey="dateCreated">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.dateCreated ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.dateCreated}
                            </Moment>
                          ) : (null)}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={160} sortable resizable>
                  <HeaderCell>Company Name</HeaderCell>
                  <Cell dataKey="companyDashboardName" />
                </Column>
                <Column width={140} sortable resizable>
                  <HeaderCell>Last active</HeaderCell>
                  <Cell dataKey="lastActiveSort">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.lastActive ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.lastActive.seconds ? (
                                rowData.lastActive.seconds * 1000
                              ) : (
                                rowData.lastActive
                              )}
                            </Moment>
                          ) : (null)}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={150} resizable>
                  <HeaderCell>Permission level</HeaderCell>
                  <Cell dataKey="role">
                    {(rowData, rowIndex) => {
                      return (
                        <div> Admin </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={130} resizable>
                  <HeaderCell>Account access</HeaderCell>
                  <Cell dataKey="approve">
                    {(rowData, rowIndex) => {
                      return (
                        <Toggle disabled={showNoPermission || !rowData.hasVerifiedEmail} checked={rowData.status !== 'pending'} onChange={(e) => this.changeUserApproval2(e, rowData.status, rowData.role, rowData.uid, rowIndex)} />
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={130} resizable>
                  <HeaderCell>Tester</HeaderCell>
                  <Cell dataKey="approve">
                    {(rowData, rowIndex) => {
                      return (
                        <Toggle disabled={showNoPermission || !rowData.hasVerifiedEmail} checked={rowData.isTester} onChange={(e) => this.changeTesterStatus(e, rowData.isTester, rowData.uid, rowIndex)} />
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={180} resizable>
                  <HeaderCell>Linked Company Account</HeaderCell>
                  <Cell dataKey="approve">
                    {(rowData, rowIndex) => {
                      return (
                        <Toggle disabled={showNoPermission || !rowData.hasVerifiedEmail || rowData.linkedCompaniesAccount} checked={rowData.linkedCompaniesAccount} onChange={(e) => this.openModalSetCompanyAccountToLinked(e, rowData.uid, rowData.linkedCompaniesAccount)} />
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={140} sortable resizable>
                  <HeaderCell>Name</HeaderCell>
                  <Cell dataKey="name">
                    {(rowData, rowIndex) => {
                      return (
                        <div> {rowData.name ? rowData.name : `${rowData.firstName} ${rowData.lastName}`} </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={150} sortable resizable>
                  <HeaderCell>Role in company</HeaderCell>
                  <Cell dataKey="roleInCompany"/>
                </Column>
                <Column width={280} sortable resizable>
                  <HeaderCell>Email</HeaderCell>
                  <Cell dataKey="email" />
                </Column>
                <Column width={155} sortable resizable>
                  <HeaderCell>Phone Number</HeaderCell>
                  <Cell dataKey="phoneNumber" />
                </Column>
                <Column width={280} sortable resizable>
                  <HeaderCell>Website</HeaderCell>
                  <Cell dataKey="website" />
                </Column>

                <Column width={160} sortable resizable>
                  <HeaderCell>Commission (%)</HeaderCell>
                  <Cell dataKey="commission" >
                    {(rowData, rowIndex) => {
                      return (
                        <>
                          {(commissionCompanyId === rowData.uid) ? (
                            <>
                              <div onClick={(e) => e.stopPropagation()} style={{ display: 'inline-block', width: '50%' }}>
                                <Input
                                  name="commissionEntered"
                                  value={commissionEntered}
                                  onChange={this.handleChangeCommission}
                                />
                              </div>
                              <Button style={{ display: 'inline-block', width: '25%', padding: 3 }} onClick={(e) => this.changeCompanyCommission()}>
                                <Check />
                              </Button>
                              <Button style={{ display: 'inline-block', width: '25%', padding: 3 }} onClick={(e) => this.closeChangeCommission()}>
                                <X />
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                color="link"
                                disabled={showNoPermission || !rowData.hasVerifiedEmail}
                                className="basic-information-edit-button"
                                onClick={(e) => this.openChangeCommission(rowData.uid, rowData.commission, rowIndex)}
                              >
                                {rowData.commission ? `${rowData.commission}%` : '0%'}
                              </Button>
                            </>
                          )}
                        </>
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={130} resizable>
                  <HeaderCell>Show Commission</HeaderCell>
                  <Cell dataKey="approve">
                    {(rowData, rowIndex) => {
                      return (
                        <Toggle disabled={showNoPermission || !rowData.hasVerifiedEmail} checked={rowData.showCommission} onChange={(e) => this.changeShowCommission(e, rowData.showCommission, rowData.uid, rowIndex)} />
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={145} sortable resizable>
                  <HeaderCell>Total campaigns</HeaderCell>
                  <Cell dataKey="totalVouchersCount">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.totalVouchersCount ? (
                            rowData.totalVouchersCount
                          ) : ('0')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={145} sortable resizable>
                  <HeaderCell>Live campaigns</HeaderCell>
                  <Cell dataKey="liveVouchersCount">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.liveVouchersCount ? (
                            rowData.liveVouchersCount
                          ) : ('0')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={150} sortable resizable>
                  <HeaderCell>Unique customers</HeaderCell>
                  <Cell dataKey="customers">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.customers ? (
                            rowData.customers
                          ) : ('0')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={130} sortable resizable>
                  <HeaderCell>Users Count</HeaderCell>
                  <Cell dataKey="userCount">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.userCount ? (
                            rowData.userCount + 1
                          ) : ('1')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={210} sortable resizable>
                  <HeaderCell>Last Campaign Start Date</HeaderCell>
                  <Cell dataKey="startDate">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {(rowData.startDate && rowData.startDate.seconds) ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.startDate.seconds * 1000}
                            </Moment>
                          ) : ('-')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={210} sortable resizable>
                  <HeaderCell>Last Campaign End Date</HeaderCell>
                  <Cell dataKey="endDate">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {(rowData.endDate && rowData.endDate.seconds) ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.endDate.seconds * 1000}
                            </Moment>
                          ) : ('-')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={155} sortable resizable>
                  <HeaderCell>Company Number</HeaderCell>
                  <Cell dataKey="companyNumber">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {(rowData.companyType === 'company') ? (rowData.companyNumber ? rowData.companyNumber : '-') : ('Sole trader')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={130} sortable resizable>
                  <HeaderCell>Rating</HeaderCell>
                  <Cell dataKey="ratingsAverage">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {(rowData.ratingsCount && rowData.ratingsTotalVal) ? (
                            Math.round((rowData.ratingsTotalVal / rowData.ratingsCount) * 10) / 10
                          ) : ('0')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={130} sortable resizable>
                  <HeaderCell>Rating #</HeaderCell>
                  <Cell dataKey="ratingsCount">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {(rowData.ratingsCount) ? (
                            rowData.ratingsCount
                          ) : ('0')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={280} resizable>
                  <HeaderCell>Uid</HeaderCell>
                  <Cell dataKey="uid">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.uid ? rowData.uid : ''}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={130} resizable>
                  <HeaderCell>Delete</HeaderCell>
                  <Cell dataKey="refund button">
                    {(rowData, rowIndex) => {
                      if (rowData.vouchersCount && rowData.vouchersCount > 0) {
                        return null;
                      }
                      return (
                        <Button color="link" style={{ paddingRight: 15 }} disabled={showNoPermission} onClick={(e) => this.openInitiateDeleteUser(e, rowData.uid, 'teamOwner')}>
                          Delete
                          {(userToDelete === rowData.uid) ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                        </Button>
                      );
                    }}
                  </Cell>
                </Column>

                {includeUnverified ? (
                  <Column width={200} resizable>
                    <HeaderCell>Send verification email</HeaderCell>
                    <Cell dataKey="hasVerifiedEmail">
                      {(rowData, rowIndex) => {
                        return (
                          <div>
                            {!rowData.hasVerifiedEmail ? (
                              <Button
                                color="link"
                                disabled={showNoPermission || sendVerificationEmailProcessing}
                                className="basic-information-edit-button"
                                style={{ float: 'left' }}
                                onClick={(e) => this.resendVerificationEmail(rowData.uid)}
                              >
                                {(sendVerificationEmailProcessing && sendVerificationEmailProcessing === rowData.uid) ? (
                                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                ) : (null)}
                                Send Verification email
                              </Button>
                            ) : null}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>
                ) : (null)}

                {includeUnverified ? (
                  <Column width={150} resizable>
                    <HeaderCell>Clear</HeaderCell>
                    <Cell dataKey="hasVerifiedEmail">
                      {(rowData, rowIndex) => {
                        return (
                          <div>
                            {!rowData.hasVerifiedEmail ? (
                              <Button
                                color="link"
                                disabled={showNoPermission}
                                className="basic-information-edit-button"
                                style={{ float: 'left' }}
                                onClick={(e) => this.cancelUnverified(rowData.uid)}
                              >
                                {(cancelUnverifiedProcessing && cancelUnverifiedProcessing === rowData.uid) ? (
                                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                ) : (null)}
                                Clear
                              </Button>
                            ) : null}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>
                ) : (null)}

              </Table>
              <Button className="admin-table-more-button" disabled={loadMoreSpinner} onClick={(e) => this.fetchCompaniesFilteredNextPage(10)}>
                Get 10 more
                {loadMoreSpinner === 1 ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                ) : (null)}
              </Button>
              <Button className="admin-table-more-button" disabled={loadMoreSpinner} onClick={(e) => this.fetchCompaniesFilteredNextPage(100)}>
                Get 100 more
                {loadMoreSpinner === 2 ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                ) : (null)}
              </Button>
              <Button className="admin-table-more-button" disabled={loadMoreSpinner} onClick={(e) => this.fetchCompaniesFilteredNextPage('all')}>
                Get all
                {loadMoreSpinner === 3 ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                ) : (null)}
              </Button>
              <Button className="admin-table-more-button" color="primary" onClick={() => this.downloadData(users)}>
                Download Data
                {downloadSpinner ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                ) : (null)}
              </Button>
            </div>
          </CardBody>
        </Card>
        <Modal isOpen={createCompanyModal} toggle={this.closeCreateComapny} scrollable={false} contentClassName="modal-max-height-page">
          <ModalHeader toggle={this.closeCreateComapny}>
            Create new user
          </ModalHeader>
          <ScrollArea
            speed={0.6}
            smoothScrolling={true}
            className="area"
            contentClassName="content"
            horizontal={false}
            ref={(el) => { this.modalScrollbar = el; }}
          >
          <ModalBody>
            {(!!createCompanyError) && <Alert color="danger">{createCompanyError}</Alert>}
            <Form>
              <FormGroup>
                  <Label for="name">Name<div className="mandatory">*</div></Label>
                <Input
                    name="name"
                    id="name"
                  placeholder=""
                    value={name}
                  onChange={this.handleChange}
                />
              </FormGroup>
            {/*  <FormGroup>
                <Label for="roleInCompany">Role in Company<div className="mandatory">*</div></Label>
                <Input
                  name="roleInCompany"
                  id="roleInCompany"
                  placeholder=""
                  value={roleInCompany}
                  onChange={this.handleChange}
                />
              </FormGroup> */}
              <FormGroup>
                <Label for="roleInCompany">
                  Role in Company
                </Label>
                <Input id="roleInCompany" type="select" value={roleInCompany} onChange={this.selectRoleinCompany}>
                  <option value={'owner'}>Owner</option>
                  <option value={'CEO'}>CEO</option>
                  <option value={'MD'}>MD</option>
                  <option value={'manager'}>Manager</option>
                  <option value={'employee'}>Employee</option>
                  <option value={'agency/agent'}>Agency/Agent</option>
                  <option value={'intern'}>Intern</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="companyName">Company Registered Name<div className="mandatory">*</div></Label>
                <Input
                  name="companyName"
                  id="companyName"
                  placeholder=""
                  value={companyName}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="companyNumber">Company Number #<div className="mandatory">*</div></Label>
                <Input
                  type="number"
                  name="companyNumber"
                  id="companyNumber"
                  placeholder=""
                  value={companyNumber}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="phoneNumber">Phone number</Label>
                <Input
                  type="number"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder=""
                  value={phoneNumber}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email<div className="mandatory">*</div></Label>
                <Input
                  name="email"
                  id="email"
                  placeholder="john@doe.corp"
                  value={email}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password<div className="mandatory">*</div></Label>
                <Input
                  type={showPassword1}
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  value={password}
                  onChange={this.handleChange}
                />
                {showPassword1 === 'input' ? (
                  <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(1)}/>
                ):(
                  <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(1)}/>
                )}

              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword">Confirm Password<div className="mandatory">*</div></Label>
                <Input
                  type={showPassword2}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="••••••••"
                  value={confirmPassword}
                  onChange={this.handleChange}
                />
                {showPassword2 === 'input' ? (
                  <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(2)}/>
                ):(
                  <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(2)}/>
                )}
              </FormGroup>
            </Form>
            <div> <div className="mandatory">*</div>Mandatory fields </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.createCompany}>Create</Button>
            <Button color="secondary" onClick={this.closeCreateComapny}>Close</Button>
          </ModalFooter>
          </ScrollArea>
        </Modal>

        <Modal isOpen={approveUserModal} toggle={this.closeApproveModal}>
          <ModalHeader toggle={this.closeApproveModal}>
            Change user approval
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="role">
                  User approval<div className="mandatory">*</div>
                </Label>
                <Input type="select" value={approveUserValue} onChange={this.changeUserApproval}>
                  <option value="approved">Approved</option>
                  <option value="pending">Pending</option>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeApproveModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={setCompanyAccountToLinkedModal} toggle={this.closeModalSetCompanyAccountToLinked}>
          <ModalHeader toggle={this.closeModalSetCompanyAccountToLinked}>
            Are you sure?
          </ModalHeader>
          <ModalFooter>
            <Button color="primary" onClick={this.setCompanyAccountToLinked}>
              Yes
              {setCompanyAccountToLinkedSpinner ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
            <Button color="secondary" onClick={this.closeModalSetCompanyAccountToLinked}>No</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={changeRoleModal} toggle={this.closeChangeRole}>
          <ModalHeader toggle={this.closeChangeRole}>
            Change permission levels
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="role">
                  Permission levels<div className="mandatory">*</div>
                </Label>
                <Input type="select" value={role} onChange={this.selectRole}>
                  {roles && Object.keys(roles).map(key => (
                    <option value={key}>{roles[key].name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.changeRole}>Change role</Button>
            <Button color="secondary" onClick={this.closeChangeRole}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={companyLogoModal} toggle={this.closeCompanyLogo}>
          <ModalHeader toggle={this.closeCompanyLogo}>
            Logo
          </ModalHeader>
          <ModalBody>
            {companyLogo ? (
              <Media src={companyLogo} />
            ):(
              <div>No logo </div>
            )}
          </ModalBody>
        </Modal>

        <Modal isOpen={initiateDeleteUserModal} toggle={this.closeInitiateDeleteUser}>
          <ModalHeader toggle={this.closeInitiateDeleteUser}>
            Do you want to delete this user?
          </ModalHeader>
          <ModalBody>
            {(!!deleteUserErr) && <Alert color="danger">{deleteUserErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.confirmInitiateDeleteUser}>Yes</Button>
            <Button color="secondary" onClick={this.closeInitiateDeleteUser}>No</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={deleteUserModal} toggle={this.closeDeleteUser}>
          <ModalHeader toggle={this.closeDeleteUser}>
            Are you sure?
          </ModalHeader>
          <ModalBody>
            {(!!deleteUserErr) && <Alert color="danger">{deleteUserErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteUser}>
              Yes
              {deletingUser ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
            <Button color="secondary" onClick={this.closeDeleteUser}>No</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  roles: state.users.companiesRoles || {},
  adminsRoles: state.admin.adminsRoles || {},
  users: state.admin.companyOwners || [],
  companyUsers: state.admin.companyUsers || [],
  companyLogo: state.admin.companyLogo || null,
  companiesComplianceData: state.admin.companiesComplianceData || {},
  companiesApiActiveType: state.admin.companiesApiActiveType || {},
});

const mapDispatchToProps = {
  getRoles: getAllRoles,
  createComp: createCompany,
  changeRole: changeUserRole,
  deleteU: deleteUser,
  getUser: getUserData,
  fetchCompanyMembers: getCompanyMembers,
  fetchCompanyLogo: getCompanyLogo,
  approveUser: approvePendingUser,
  pauseUser: pauseActiveUser,
  updateWebsite: updateCompanyWebsite,
  fetchUnverifiedCount: getUnverifiedCount,
  fetchCompaniesFiltered: getDataFiltered,
  fetchCompaniesFilteredNextPage: getDataFilteredNextPage,
  sortAdminData: sortData,
  accessCompanyDashboard: openCompanyDashboard,
  changeCompanyCommission: changeCompanyDefaultCommission,
  changeUserTesterStatus: changeTesterStatus,
  changeShowCommission: changeCompanyShowCommission,
  sendVerificationEmail: sendVerificationEmailAsAdmin,
  cancelUnverified: cancelUnverifiedCompany,
  fetchCompaniesComplianceData: getCompaniesComplianceData,
  setCompanyAccountToLinked: setCompanyAccountToLinkedCompaniesAccount,
  fetchCompaniesActiveAPIData: getCompaniesActiveAPIData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllUsers));
