import { firebase, firestore, storage, auth } from '../../lib/firebase';
import {
  CONTENT_MANAGEMENT_DATA,
  UPDATE_CONTENT_MANAGEMENT_DATA,
  DASHBOARD_TOOLTIPS,
  NEW_VOUCHER_TOOLTIPS,
  API_TOOLTIPS,
  PAYMENT_TOOLTIPS,
  COMPANY_POPOUT_TOOLTIPS,
  COMPANY_TABS_TOOLTIPS,
  COMPANY_INFO_TOOLTIPS,
  PENDING_TAB,
  CONTACT_TAB,
  API_TAB,
  NEW_VOUCHER_DESCRIPTION,
  CONTENT_MANAGEMENT_FOOTERS,
  FOOTER_ICONS,
  BRANDING_DATA,
  UPDATE_BRANDING_DATA,
  EVENT_CATEGORIES,
  UPDATE_EVENT_CATEGORY,
  REMOVE_EVENT_CATEGORY,
  FAQ_LINKS,
  SIGNPOSTS,
} from '../index';

// CONTENT MANAGEMENT

export const getFooterPage = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - getFooterPage', { id });

    const pagesRef = firestore.collection('pages').doc(id);
    const snapshot = await pagesRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('getFooterPage data', { data });

    //  dispatch({
    //    type: FOOTER_ICONS,
    //    payload: data,
    //  });

      return resolve(data);
    } else {
      return resolve(null);
    }
});


/*
export const getContactPageData = () => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - getContactPageData');

    const pagesRef = firestore.collection('pages').doc('contactPage');
    const snapshot = await pagesRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('getContactPageData data', { data });

      return resolve(data);
    } else {
      return resolve(null);
    }
});  */

export const getContactPageData = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getContactPageData');
    const state = getState();

    const pageRef = firestore.collection('pages').doc('contactPage');
    const snapshot = await pageRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('data', { data });

      dispatch({
        type: CONTACT_TAB,
        payload: data.data,
      });

      return resolve(data.data);
    } else {
      return resolve();
    }
});


export const updateContactPageData = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updateContactPageData', { data });
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('contactPage');

  await pageRef.set({ 'data': data }, { merge: true }).then(async (res) => {
    dispatch({
      type: CONTACT_TAB,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateContactPageData',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  dispatch({
    type: CONTACT_TAB,
    payload: data,
  });

  return resolve();
});

export const getAPIPageData = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getAPIPageData');
    const state = getState();

    const pageRef = firestore.collection('pages').doc('APIPage');
    const snapshot = await pageRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('data', { data });

      dispatch({
        type: API_TAB,
        payload: data.data,
      });

      return resolve(data.data);
    } else {
      return resolve();
    }
});


export const updateAPIPageData = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updateAPIPageData', { data });
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('APIPage');

  await pageRef.set({ 'data': data }, { merge: true }).then(async (res) => {
    dispatch({
      type: API_TAB,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateAPIPageData',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  dispatch({
    type: API_TAB,
    payload: data,
  });

  return resolve();
});


export const getNewVoucherPageData = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getNewVoucherPageData');
    const state = getState();

    const pageRef = firestore.collection('pages').doc('newVoucherPage');
    const snapshot = await pageRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('data', { data });

      dispatch({
        type: NEW_VOUCHER_DESCRIPTION,
        payload: data.data,
      });

      return resolve(data.data);
    } else {
      return resolve();
    }
});


export const updateNewVoucherPageData = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updateNewVoucherPageData', { data });
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('newVoucherPage');

  await pageRef.set({ 'data': data }, { merge: true }).then(async (res) => {
    dispatch({
      type: NEW_VOUCHER_DESCRIPTION,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateNewVoucherPageData',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  dispatch({
    type: NEW_VOUCHER_DESCRIPTION,
    payload: data,
  });

  return resolve();
});

//Question about Privacy Policy and Cookie Policy pages in web footer, in jira these say to use App Privacy Policy and App Cookie Policy, but shouldn't these be Web Privacy Policy/Cookie Policy

export const getContentManagementData = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getContentManagementData');
    const state = getState();

    const pagesRef = firestore.collection('pages');
    const snapshot = await pagesRef.get();

    if (snapshot.docs) {
      const pages = {};

      snapshot.docs.map(doc => {
        pages[doc.id] = doc.data();
      })

      dispatch({
        type: CONTENT_MANAGEMENT_DATA,
        payload: pages,
      });

      return resolve(pages);
    }
});

export const updateContentManagementData = (id, data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {

  if (!id) return reject('no id');
  if (!data) return reject('no data');

  const pagesRef = firestore.collection('pages').doc(id);
//  const doc = await pagesRef.get();

  await pagesRef.set({
    slug: id,
    text: data,
  });

  const currentUser = auth.currentUser;

  const adminActionRef = firestore.collection('adminActions');

  await adminActionRef.add({
    id: 'updateContentManagementData',
    time: firebase.firestore.FieldValue.serverTimestamp(),
    uid: currentUser.uid || null,
    pageId: id,
    data,
    name: adminName || null,
  });

  dispatch({
    type: UPDATE_CONTENT_MANAGEMENT_DATA,
    payload: data,
    key: id,
  });

  return resolve();
});

export const getDashboardTooltips = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getDashboardTooltips');
    const state = getState();

    const pageRef = firestore.collection('pages').doc('dashboardTooltips');
    const snapshot = await pageRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('data', { data });

      dispatch({
        type: DASHBOARD_TOOLTIPS,
        payload: data,
      });

      return resolve({ tooltips: data });
    } else {
      return resolve();
    }
});

export const updateDashboardTooltip = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('dashboardTooltips');

  await pageRef.set(data, { merge: true }).then(async (res) => {
    dispatch({
      type: DASHBOARD_TOOLTIPS,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateDashboardTooltip',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  return resolve();
});


export const getNewVoucherTooltips = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getNewVoucherTooltips');
    const state = getState();

    const pageRef = firestore.collection('pages').doc('newVoucherTooltips');
    const snapshot = await pageRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('getNewVoucherTooltips data', { data });

      dispatch({
        type: NEW_VOUCHER_TOOLTIPS,
        payload: data,
      });

      return resolve({ tooltips: data });
    } else {
      return resolve();
    }
});

export const updateNewVoucherTooltip = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('newVoucherTooltips');

  await pageRef.set(data, { merge: true }).then(async (res) => {
    dispatch({
      type: NEW_VOUCHER_TOOLTIPS,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateNewVoucherTooltip',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  return resolve();
});


export const getAPITooltips = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getAPITooltips');
    const state = getState();

    const pageRef = firestore.collection('pages').doc('apiTooltips');
    const snapshot = await pageRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('data', { data });

      dispatch({
        type: API_TOOLTIPS,
        payload: data,
      });

      return resolve({ tooltips: data });
    } else {
      return resolve();
    }
});

export const updateAPITooltip = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('apiTooltips');

  await pageRef.set(data, { merge: true }).then(async (res) => {
    dispatch({
      type: API_TOOLTIPS,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateAPITooltip',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

//  dispatch({
//    type: API_TOOLTIPS,
//    payload: data,
//  });

  return resolve();
});


export const getPaymentTooltips = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getAPITooltips');
  const state = getState();

  const pageRef = firestore.collection('pages').doc('paymentTooltips');
  const snapshot = await pageRef.get();

  if (snapshot.exists) {
    const data = snapshot.data();

    console.log('data', { data });

    dispatch({
      type: PAYMENT_TOOLTIPS,
      payload: data,
    });

    return resolve({ tooltips: data });
  } else {
    return resolve();
  }
});

export const updatePaymentTooltip = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('paymentTooltips');

  await pageRef.set(data, { merge: true }).then(async (res) => {
    dispatch({
      type: PAYMENT_TOOLTIPS,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updatePaymentTooltip',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  //  dispatch({
  //    type: API_TOOLTIPS,
  //    payload: data,
  //  });

  return resolve();
});



export const getCompanyPopoutData = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getCompanyPopoutData');
    const state = getState();

    const pageRef = firestore.collection('pages').doc('companyPopoutTooltips');
    const snapshot = await pageRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('getCompanyPopoutData data', { data });

      dispatch({
        type: COMPANY_POPOUT_TOOLTIPS,
        payload: data,
      });

      return resolve({ tooltips: data });
    } else {
      return resolve();
    }
});

export const updateCompanyPopoutData = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('companyPopoutTooltips');

  await pageRef.set(data, { merge: true }).then(async (res) => {
    dispatch({
      type: COMPANY_POPOUT_TOOLTIPS,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateCompanyPopoutData',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  dispatch({
    type: COMPANY_POPOUT_TOOLTIPS,
    payload: data,
  });

  return resolve();
});

export const getCompanyTabsData = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getCompanyTabsData');
    const state = getState();

    const pageRef = firestore.collection('pages').doc('companyTabsTooltips');
    const snapshot = await pageRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('getCompanyTabsData data', { data });

      dispatch({
        type: COMPANY_TABS_TOOLTIPS,
        payload: data,
      });

      return resolve({ tooltips: data });
    } else {
      return resolve();
    }
});

export const updateCompanyTabsData = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('companyTabsTooltips');

  await pageRef.set(data, { merge: true }).then(async (res) => {
    dispatch({
      type: COMPANY_TABS_TOOLTIPS,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateCompanyTabsData',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  dispatch({
    type: COMPANY_TABS_TOOLTIPS,
    payload: data,
  });

  return resolve();
});




export const getCompanyInfoData = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getCompanyInfoData');
  const state = getState();

  const pageRef = firestore.collection('pages').doc('companyInfoTooltips');
  const snapshot = await pageRef.get();

  if (snapshot.exists) {
    const data = snapshot.data();

    console.log('getCompanyInfoData data', { data });

    dispatch({
      type: COMPANY_INFO_TOOLTIPS,
      payload: data,
    });

    return resolve({ tooltips: data });
  } else {
    return resolve();
  }
});

export const updateCompanyInfoData = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('companyInfoTooltips');

  await pageRef.set(data, { merge: true }).then(async (res) => {
    dispatch({
      type: COMPANY_INFO_TOOLTIPS,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateCompanyInfoData',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  dispatch({
    type: COMPANY_INFO_TOOLTIPS,
    payload: data,
  });

  return resolve();
});






export const getPendingTabData = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getPendingTabData');
    const state = getState();

    const pageRef = firestore.collection('pages').doc('pendingTab');
    const snapshot = await pageRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('data', { data });

      dispatch({
        type: PENDING_TAB,
        payload: data.data,
      });

      return resolve(data.data);
    } else {
      return resolve();
    }
});

export const updatePendingTabData = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('pendingTab');

  await pageRef.set({ 'data': data }, { merge: true }).then(async (res) => {
    dispatch({
      type: PENDING_TAB,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updatePendingTabData',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  dispatch({
    type: PENDING_TAB,
    payload: data,
  });

  return resolve();
});


export const getFooters = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getFooters');
    const state = getState();

    const pageRef = firestore.collection('pages').doc('footers');
    const snapshot = await pageRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('data', { data });

      dispatch({
        type: CONTENT_MANAGEMENT_FOOTERS,
        payload: data,
      });

      return resolve({ data: data });
    } else {
      return resolve();
    }
});

export const updateFooter = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data) return reject('no data');

  const pageRef = firestore.collection('pages').doc('footers');

  await pageRef.set(data, { merge: true }).then(async (res) => {
    dispatch({
      type: CONTENT_MANAGEMENT_FOOTERS,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateFooter',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  dispatch({
    type: CONTENT_MANAGEMENT_FOOTERS,
    payload: data,
  });

  return resolve();
});


export const getFooterIcons = () => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getFooterIcons');

  const twitterReference = storage.ref('footerIcons/twitter/twitter.png');
  const facebookReference = storage.ref('footerIcons/facebook/facebook.png');
  const youtubeReference = storage.ref('footerIcons/youtube/youtube.png');
  const linkedInReference = storage.ref('footerIcons/linkedIn/linkedIn.png');

  twitterReference.getDownloadURL().then((url) => {
    dispatch({
      type: FOOTER_ICONS,
      payload: { url, type: 'twitter' },
    });
  //  return resolve(url);
  });

  facebookReference.getDownloadURL().then((url) => {
    dispatch({
      type: FOOTER_ICONS,
      payload: { url, type: 'facebook' },
    });
  });

  youtubeReference.getDownloadURL().then((url) => {
    dispatch({
      type: FOOTER_ICONS,
      payload: { url, type: 'youtube' },
    });
  });

  linkedInReference.getDownloadURL().then((url) => {
    dispatch({
      type: FOOTER_ICONS,
      payload: { url, type: 'linkedIn' },
    });
  });

  return resolve();
});


export const updateFooterIconUrl = (url, type) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - updateFooterIconUrl', { url, type });

  if (!url) return reject('no url');
  if (!type) return reject('no type');

  dispatch({
    type: FOOTER_ICONS,
    payload: { url, type },
  });
});

export const getBrandingData = () => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getBrandingData');

  const logoReference = storage.ref('siteImages/logo.png');
  const faviconReference = storage.ref('siteImages/favicon.png');

  let logo;
  let favicon;

  let brandingData = {};

  const brandingRef = firestore.collection('siteInfo').doc('siteInfo');
  const snapshot = await brandingRef.get();

  if (snapshot.exists) {
    const data = snapshot.data();

    console.log('getBrandingData data', { data });

    brandingData = data;
  }

  await logoReference.getDownloadURL().then((url) => {
    logo = url;
  }).catch(err => {
    console.log(err);
    logo = '';
  });

  await faviconReference.getDownloadURL().then((url) => {
    favicon = url;
  }).catch(err => {
    console.log(err);
    favicon = '';
  });

  console.log('getBrandingData data 2', { brandingData, logo, favicon });

  dispatch({
    type: BRANDING_DATA,
    payload: { ...brandingData, logo, favicon },
  });

  return resolve(brandingData);
});

export const updateBrandingData = (data, type, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - updateBrandingData', { data, type });

  if (!data) return reject('no data');
  if (!type) return reject('no type');

  if (type === 'siteName' || type === 'tabInfo' || type === 'colorTheme' || type === 'sidebarPrimaryColor' || type === 'sidebarSecondaryColor' || type === 'sidebarTextColor'
    || type === 'contentBackgroundColor' || type === 'headerBackgroundColor' || type === 'perksBackgroundColor' || type === 'perksTextColor') {
    const pageRef = firestore.collection('siteInfo').doc('siteInfo');

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateBrandingData',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      dataType: type,
      data,
      name: adminName || null,
    });

    await pageRef.set({ [type]: data }, { merge: true }).then((res) => {
      dispatch({
        type: UPDATE_BRANDING_DATA,
        payload: { data, type },
      });

      return resolve('success');

      console.log(res);
    }).catch(err => {
      return resolve('error');

      console.log(err);
    });
  } else {
    dispatch({
      type: UPDATE_BRANDING_DATA,
      payload: { data, type },
    });

    return resolve('success');
  }
});


export const updateVoucherCategory = (id, value, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - updateVoucherCategory', { id, value });

  if (!id) return reject('no id');
  // if (!value) return reject('no value');

  const categoryRef = firestore.collection('categories').doc(id);

  await categoryRef.set({ enabled: value || false }, { merge: true }).then(async (res) => {

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateVoucherCategory',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      categoryId: id,
      enabled: value,
      name: adminName || null,
    });

    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});

export const addVoucherCategory = (name, icon, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - addVoucherCategory', { name, icon });

  if (!name) return reject('no name');
  if (!icon) return reject('no icon');

  const categoryRef = firestore.collection('categories');

  var imageRef = storage.ref(`categories/icons`).child(`${name}.svg`);

  if (icon && icon.type === 'image/svg+xml') {
    let file = icon;
    file.lastModifiedDate = new Date();
    file.name = name;


    console.log('file', { file });

    await imageRef.put(file).then(async (snapshot) => {
      console.log('Uploaded a blob or file!');
      await snapshot.ref.getDownloadURL().then(async (downloadURL) => {
        console.log('File available at', downloadURL);

        categoryRef.add({ name, icon: `categories/icons/${name}.svg`, enabled: false, order: 999 }).then(async (res) => {

          const currentUser = auth.currentUser;

          const adminActionRef = firestore.collection('adminActions');

          await adminActionRef.add({
            id: 'addVoucherCategory',
            time: firebase.firestore.FieldValue.serverTimestamp(),
            uid: currentUser.uid || null,
            name,
            icon: downloadURL,
            name: adminName || null,
          });

          //  dispatch({
          //    type: UPDATE_EVENT_CATEGORY,
          //    payload: { data: data, id: res.id },
          //  });
          return resolve({ status: 'success', id: res.id, data: { name, icon: `categories/icons/${name}.svg`, url: downloadURL, enabled: true }});
        }).catch(err => {
          return resolve({ status: 'error' });
        });
      });
    });
  }

  /* await imageRef.put(icon).then(async (snapshot) => {
    console.log('Uploaded a blob or file!');
    await snapshot.ref.getDownloadURL().then(async (downloadURL) => {
      console.log('File available at', downloadURL);

      categoryRef.add({ name, icon: `categories/icons/${name}.svg`, enabled: true }).then((res) => {

        //  dispatch({
        //    type: UPDATE_EVENT_CATEGORY,
        //    payload: { data: data, id: res.id },
        //  });
        return resolve({ status: 'success', id: res.id, data: { name, icon: `categories/icons/${name}.svg`, url: downloadURL, enabled: true }});
      }).catch(err => {
        return resolve({ status: 'error' });
      });
    });
  }); */
});

export const editVoucherCategory = (categoryId, name, icon, changeIcon, oldIcon, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - editVoucherCategory', { categoryId, name, icon, changeIcon });

  if (!categoryId) return reject('no categoryId');
  if (!name) return reject('no name');
  if (changeIcon && !icon) return reject('no icon');

  if (!changeIcon) {
    const categoryRef = firestore.collection('categories').doc(categoryId);

    categoryRef.update({ name }).then(async (res) => {
      const snapshot = await categoryRef.get();

      if (snapshot.exists) {
        const newData = snapshot.data();

        console.log('newData', { newData });

        const currentUser = auth.currentUser;

        const adminActionRef = firestore.collection('adminActions');

        await adminActionRef.add({
          id: 'editVoucherCategory',
          time: firebase.firestore.FieldValue.serverTimestamp(),
          uid: currentUser.uid || null,
          categoryId,
          categoryName: name,
          name: adminName || null,
        });

        if (newData) {
          return resolve({ status: 'success', id: categoryId, data: { name, icon: newData.icon, url: oldIcon, enabled: newData.enabled }});
        } else {
          return resolve({ status: 'error' });
        }
      } else {
        return resolve({ status: 'error' });
      }
    }).catch(err => {
      console.log(err);
      return resolve({ status: 'error' });
    });

  } else {
    const categoryRef = firestore.collection('categories').doc(categoryId);

    var imageRef = storage.ref(`categories/icons`).child(`${name}.svg`);

    if (icon && icon.type === 'image/svg+xml') {
      let file = icon;
      file.lastModifiedDate = new Date();
      file.name = name;


      console.log('file', { file });

      await imageRef.put(file).then(async (snapshot) => {
        console.log('Uploaded a blob or file!');
        await snapshot.ref.getDownloadURL().then(async (downloadURL) => {
          console.log('File available at', downloadURL);

          categoryRef.update({ name, icon: `categories/icons/${name}.svg` }).then(async (res) => {
            const snapshot = await categoryRef.get();

            if (snapshot.exists) {
              const newData = snapshot.data();

              console.log('newData', { newData });

              const currentUser = auth.currentUser;

              const adminActionRef = firestore.collection('adminActions');

              await adminActionRef.add({
                id: 'editVoucherCategory',
                time: firebase.firestore.FieldValue.serverTimestamp(),
                uid: currentUser.uid || null,
                categoryId,
                categoryName: name,
                icon: downloadURL,
                name: adminName || null,
              });

              if (newData) {
                return resolve({ status: 'success', id: categoryId, data: { name, icon: `categories/icons/${name}.svg`, url: downloadURL, enabled: newData.enabled }});
              } else {
                return resolve({ status: 'success', id: categoryId, data: { name, icon: `categories/icons/${name}.svg`, url: downloadURL, enabled: true }});
              }
            } else {
              return resolve({ status: 'success', id: categoryId, data: { name, icon: `categories/icons/${name}.svg`, url: downloadURL, enabled: true }});
            }
          //  return resolve({ status: 'success', id: categoryId, data: { name, icon: `categories/icons/${name}.svg`, url: downloadURL, enabled: res.enabled }});
          }).catch(err => {
            console.log(err);
            return resolve({ status: 'error' });
          });
        });
      });
    }
  }

});


export const saveAllVoucherCategories = (categories) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - saveAllVoucherCategories', { categories });

  if (!categories || !categories.length) return reject('no categories');

    categories.map(async (category) => {
      const categoryRef = firestore.collection('categories').doc(category.key);
      console.log('update', { key: category.key, order: category.order });
      await categoryRef.update({ order: category.order }).then(async (res) => {
      /*  const snapshot = await categoryRef.get();

        if (snapshot.exists) {
          const newData = snapshot.data();

          console.log('newData', { newData });

          if (newData) {
            return resolve({ status: 'success', id: category.key });
          } else {
            return resolve({ status: 'error' });
          }
        } else {
          return resolve({ status: 'error' });
        } */
      }).catch(err => {
        console.log(err);
      //  return resolve({ status: 'error' });
      });
    });

    return resolve({ status: 'success' });

});


export const removeVoucherCategory = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - removeVoucherCategory', { id });

  if (!id) return reject('no id');

  const categoryRef = firestore.collection('categories').doc(id);

  await categoryRef.delete().then((res) => {

  //  dispatch({
  //    type: REMOVE_EVENT_CATEGORY,
  //    payload: { id: id },
  //  });
    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});


export const editRewardsUnlocked = (icon, oldIcon) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - editRewardsUnlocked', { icon });

  if (!icon) return reject('no icon');

    const categoryRef = firestore.collection('images').doc('rewardsUnlocked');

    var imageRef = storage.ref(`images/icons`).child(`rewardsUnlocked.svg`);

    if (icon && icon.type === 'image/svg+xml') {
      let file = icon;
      file.lastModifiedDate = new Date();
      file.name = 'rewardsUnlocked';


      console.log('file', { file });

      await imageRef.put(file).then(async (snapshot) => {
        console.log('Uploaded a blob or file!');
        await snapshot.ref.getDownloadURL().then(async (downloadURL) => {
          console.log('File available at', downloadURL);

          categoryRef.set({ icon: `images/icons/rewardsUnlocked.svg` }, { merge: true }).then(async (res) => {
            const snapshot = await categoryRef.get();

            if (snapshot.exists) {
              const newData = snapshot.data();

              console.log('newData', { newData });

              if (newData) {
                return resolve({ status: 'success', data: { icon: `images/icons/rewardsUnlocked.svg`, url: downloadURL }});
              } else {
                return resolve({ status: 'success', data: { icon: `images/icons/rewardsUnlocked.svg`, url: downloadURL }});
              }
            } else {
              return resolve({ status: 'success', data: { icon: `images/icons/rewardsUnlocked.svg`, url: downloadURL }});
            }
          //  return resolve({ status: 'success', id: categoryId, data: { name, icon: `categories/icons/${name}.svg`, url: downloadURL, enabled: res.enabled }});
          }).catch(err => {
            console.log(err);
            return resolve({ status: 'error' });
          });
        });
      });
    }
});


export const getSignposts = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getSignposts');
  const state = getState();

  const signpostsRef = firestore.collection('signposts').doc('signposts');
  const snapshot = await signpostsRef.get();

  if (snapshot.exists) {
    const signposts = snapshot.data();

    console.log('getSignposts signposts', { signposts });

    dispatch({
      type: SIGNPOSTS,
      payload: signposts ? signposts : null,
    });

    return resolve({ signposts: signposts ? signposts : null });
  } else {
    console.log('getSignposts no data');
    return resolve();
  }
});

export const updateSignposts = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - updateSignposts', { data });

  if (!data || !data.text || !data.textLink) return reject('no data');

  const signpostsRef = firestore.collection('signposts').doc('signposts');

  await signpostsRef.set({
  //  buttonText: data.buttonText,
  //  buttonLink: data.buttonLink,
    text: data.text,
    textLink: data.textLink,
  }, { merge: true }).then(async (res) => {
    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateSignposts',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      name: adminName || null,
    });

    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});

export const updateEventCategory = (id, value, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - updateEventCategory', { id, value });

  if (!id) return reject('no id');
  // if (!value) return reject('no value');

  const categoryRef = firestore.collection('eventCategories').doc(id);

  await categoryRef.set({ enabled: value || false }, { merge: true }).then(async (res) => {
    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateEventCategory',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      categoryId: id,
      enabled: value,
      name: adminName || null,
    });

    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});

export const updateEventCategories = (categories, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - updateEventCategories', { categories });

  if (!categories) return reject('no categories');
  // if (!value) return reject('no value');

  const eventCategoriesRef = firestore.collection('appSettings').doc('eventOptions');

//  return resolve('success');

  await eventCategoriesRef.set({ settingValue: categories }, { merge: true }).then(async (res) => {
    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateEventCategories',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      name: adminName || null,
    });

    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});

export const getEventCategories = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getEventCategories');
    const state = getState();

    const eventCategoriesRef = firestore.collection('appSettings').doc('eventOptions');
    const snapshot = await eventCategoriesRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('getEventCategories data', { data });

      dispatch({
        type: EVENT_CATEGORIES,
        payload: data.settingValue,
      });

      return resolve({ categories: data.settingValue });
    } else {
      console.log('getEventCategories no data');
      return resolve();
    }
});


export const addEventCategory = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - addEventCategory', { data });

  if (!data) return reject('no data');

  const categoryRef = firestore.collection('eventCategories');

  await categoryRef.add(data).then(async (res) => {

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'addEventCategory',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      name: adminName || null,
    });

    dispatch({
      type: UPDATE_EVENT_CATEGORY,
      payload: { data: data, id: res.id },
    });
    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});

export const removeEventCategory = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - removeEventCategory', { id });

  if (!id) return reject('no id');

  const categoryRef = firestore.collection('eventCategories').doc(id);

  await categoryRef.delete().then((res) => {

    dispatch({
      type: REMOVE_EVENT_CATEGORY,
      payload: { id: id },
    });
    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});


export const getFAQLinks = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getFAQLinks');

  const faqLinksRef = firestore.collection('faqLinks').doc('faqLinks');
  const snapshot = await faqLinksRef.get();

  if (snapshot.exists) {
    const data = snapshot.data();

    console.log('getFAQLinks data', { data });

    dispatch({
      type: FAQ_LINKS,
      payload: data,
    });

    return resolve({ categories: data });
  } else {
    console.log('getFAQLinks no data');
    return resolve();
  }
});


export const updateFAQLinks = (id, data) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - updateFAQLinks', { id, data });

  if (!id || !data) return reject('no data');

  const faqLinksRef = firestore.collection('faqLinks').doc('faqLinks');

  await faqLinksRef.set({ [id]: data }, { merge: true }).then(async (res) => {

    const snapshot = await faqLinksRef.get();

    if (snapshot.exists) {
      const data = snapshot.data();

      console.log('getFAQLinks data', { data });

      dispatch({
        type: FAQ_LINKS,
        payload: data,
      });
    } else {
      console.log('getFAQLinks no data');
    }

    return resolve('success');

    /* dispatch({
      type: FAQ_LINKS,
      payload: res.data,
    }); */
    // return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});

/*
export const editFooterIcon = (id, icon) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - editFooterIcon', { id, icon });

  if (!id) return reject('no id');
  if (!icon) return reject('no icon');

  const user = auth.currentUser;

  if (user) {
    var imageRef = storage.ref(`footerIcons/${id}`);

    await imageRef.put(icon).then(async (snapshot) => {
      console.log('Uploaded a blob or file!');
      await snapshot.ref.getDownloadURL().then(async (downloadURL) => {
        console.log('File available at', downloadURL);
      });
    });
  }
});
*/
